<template>
<v-col cols="12" v-if="cotizacion.data_cotizacion">

            <v-row class="pt-5">
                <v-col cols="5" align="left"><img src="@/assets/img/logo_texto.svg" style="width:50%"/></v-col>
                <v-col cols="2" align="center" class="align-center justify-center" style="padding-top: 3rem;"> <span style="font-size: 4rem;">X</span></v-col>
                <v-col cols="5" align="right">
                    <template v-if="cotizacion.view == 'cotizacion_view'">
                        <span>COTIZACION</span><br>
                        <span>NRO DE COTIZACION {{ cotizacion.data_cotizacion.numero_cotizacion }}</span><br>
                        <span>{{ cotizacion.data_cotizacion.fecha_actual }}</span><br>
                        <h5><strong>COTIZACION | PRESUPUESTO</strong></h5>
                    </template>

                    <template v-if="cotizacion.view == 'pedido_view'">
                        <span>PEDIDO</span><br>
                        <span>NRO DE PEDIDO {{ cotizacion.data_cotizacion.numero_cotizacion }}</span><br>
                        <span>{{ cotizacion.data_cotizacion.fecha_actual }}</span><br>                
                    </template>
                    

                </v-col>
            </v-row>

            <v-row>
                <v-divider></v-divider>
            </v-row>
            
            <v-row class="pt-5">
            
                <v-col cols="6" align="left">
                    <template v-if="cotizacion.cliente">
                        <strong> {{cotizacion.cliente.nombre}}</strong><br>
                        {{cotizacion.cliente.domicilio}}<br>
                        {{cotizacion.cliente.codigo_postal}} {{cotizacion.cliente.provincia}}<br>
                        {{cotizacion.cliente.identificacion}} {{cotizacion.cliente.tipo_iva}}<br>
                        {{cotizacion.cliente.telefono}}<br>
                    </template>
                    
                    <template v-else>
                        <strong>SIN DATOS</strong><br>
                        
                    </template>
                    REFERENCIA :              

                </v-col>
                <v-col cols="6" align="right">
                        <span>PEDIDO CLIENTE NRO {{ cotizacion.data_cotizacion.numero_cotizacion }}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-divider></v-divider>
            </v-row>

            <v-row class="pt-5" v-for="(data_piezas,index) in cotizacion.data" :key="index" style="border-bottom: solid gray;">
            <v-col cols="12">

                <p>
                    <strong>Producto:  {{data_piezas.producto}}</strong> | <strong>Cantidad:  {{data_piezas.data.cantidad}}</strong>
                </p>
                <p>
                    <template v-for="(opcion,ind) in data_piezas.data.opciones">
                        <strong :key="ind"> {{ opcion.propiedad }} </strong> : {{ opcion.opcion }} |
                    </template>
                </p>
            <p>
                    <template v-for="(rango,indr) in data_piezas.data.rangos">
                        <strong :key="indr"> {{ rango.propiedad }} </strong> : {{ rango.opcion }}  ({{rango.valor}})|
                    </template>
            </p>

            </v-col>
            </v-row>

            <v-row class="pt-5">
                <v-col cols="12">
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Descripción</th>
                                    <th>Cantidad</th>
                                    <th v-if="cotizacion.view == 'cotizacion_view'">P.Unit</th>
                                    <th  v-if="cotizacion.view == 'cotizacion_view'">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(pieza,idx_pieza) in cotizacion.piezas" :key="idx_pieza">
                                    <td>{{pieza.codigo}}</td>
                                    <td>{{pieza.nombre}}</td>
                                    <td>{{pieza.cantidad}}</td>
                                    <td v-if="cotizacion.view == 'cotizacion_view'">{{pieza.precio}}</td>
                                    <td v-if="cotizacion.view == 'cotizacion_view'">{{pieza.total_pieza}}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>

                    <v-simple-table v-if="cotizacion.view == 'cotizacion_view'">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>Subtotal</th>
                                    <th>Bonif %{{cotizacion.data_cotizacion.bonificacion}}</th>
                                    <th>Grabado</th>
                                    <th>IVA %21</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{cotizacion.totales.data_subtotal}}</td>
                                    <td>{{cotizacion.totales.bonificacion}}</td>
                                    <td>{{cotizacion.totales.grabado}}</td>
                                    <td>{{cotizacion.totales.iva}}</td>
                                    <td>{{cotizacion.totales.total}}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>

                </v-col>       
            </v-row>
        
    

</v-col>
</template>

<script>
import {mapActions,mapState} from 'vuex'

export default {
    name:'CotizacionView',
    props:{
        cotizacion:[Object,Array],
    },
     data(){
      return {
        buscando:false,
      }
    },
    components: {
    },
    methods:{
       
    },
    computed: {
    },
    created(){
    }
}
</script>