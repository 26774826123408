<template>
  <div>
    <v-breadcrumbs :items="items" class="pt-0">
        <template v-slot:divider>
            <v-icon :style="{'font-size': '10px'}">fas fa-chevron-right</v-icon>
        </template>
    </v-breadcrumbs>

      <div>        
        
        <v-card
        class="mx-auto"
        outlined
        elevation="10"
       max-width="1000"
        >
        <div v-if="buscando">
            <v-progress-linear
            indeterminate
            color="primary"
            ></v-progress-linear>
        </div>
          <v-data-table
            :headers="headers"
            :items="roles"
            :search="search"
          >
            <template v-slot:top>
              <v-toolbar
                flat
              >                 
                  <v-toolbar-title>Roles</v-toolbar-title>
                  <v-divider
                    class="mx-4"
                    inset
                    vertical
                  ></v-divider>
                  <v-spacer>
                  </v-spacer>
                    <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    hide-details
                    class="col-md-4 mr-6"
                  ></v-text-field>

                  <v-btn
                      color="success"
                      dark
                      class="mb-2"                      
                      elevation="12"
                      v-if="verificarPermiso(25)"
                      @click="openModal(null,'agregar')"        
                    >
                    <v-icon>fas fa-plus </v-icon>
                  </v-btn>

                  <v-dialog
                    v-model="dialog"
                    persistent
                    max-width="80%"
                    scrollable
                  >                  
                    <v-card>
                      <v-card-title>
                        <span class="text-h5" v-if="tipo == 'editar'">Editar Rol</span>
                        <span class="text-h5" v-if="tipo == 'agregar'">Agregar Rol</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>                           
                            <v-col md="12" xs="12">
                                  <v-text-field
                                  v-model="rol.descripcion"
                                  label="Nombre"                   
                                  ></v-text-field>
                            </v-col>            
                          
                          </v-row>
                          <v-row>
                            <v-divider></v-divider>
                          </v-row>

                          <v-row>
                            <v-col md="12" xs="12">
                              <v-card>
                                  <v-card-title class="text-h6">
                                      Permisos
                                  </v-card-title>                                                            
                                  <v-card-text>
                                    <v-col cols="12" md="12">
                                        <v-row>
                                            <v-col cols="3" md="3"
                                            v-for="(grupo,index) of grupo_permisos"
                                            :key="index"
                                            >
                                                <v-card
                                                    class="mx-auto"
                                                    max-width="344"                                           
                                                >
                                                    <v-card-actions>
                                                    <v-btn
                                                        text
                                                        color="indigo darken-3"
                                                    > 
                                                        {{grupo.descripcion}}                                       
                                                    </v-btn>
                                                    </v-card-actions>

                                                    <v-expand-transition>
                                                    <div>
                                                        <v-divider></v-divider>
                                                        <v-card-text>
                                                         
                                                          <v-checkbox
                                                            v-for="(permiso,idx) of grupo.permisos"
                                                            v-model="rol.permisos"
                                                            :input-value="(rol.permisos.includes(permiso.id))"
                                                            :label="permiso.descripcion"
                                                            color="indigo darken-3"
                                                            :value="permiso.id"
                                                            :key="idx"
                                                            hide-details
                                                            ></v-checkbox>
                                                                                                            
                                                        </v-card-text>
                                                    </div>
                                                    </v-expand-transition>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                  </v-card-text>
                                  <v-card-actions>
                                     
                                  </v-card-actions>
                              </v-card>
                            </v-col>
                          </v-row>

                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="dialog = false"
                        >
                          Cerrar
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          v-if="tipo == 'editar'"
                          @click="editar"
                        >
                          Editar
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          v-if="tipo == 'agregar'"
                          @click="agregar"
                        >
                          Agregar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  

                  <v-dialog v-model="dialogDelete" max-width="550">
                    <v-card>
                      <v-toolbar
                        color="primary"
                        dark
                      >Eliminar
                      </v-toolbar>
                      <v-card-text class="text-h6 pa-10">Está seguro que desea eliminar el rol?</v-card-text>
                      <v-card-actions  class="justify-end">
                        <v-spacer></v-spacer>
                        <v-btn color="darken-1" text @click="closeDelete">Cancelar</v-btn>
                        <v-btn color="darken-1" class="primary" primary text @click="deleteItemConfirm">Confirmar</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">              
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                   <v-icon
                    v-on="on"
                    small
                    class="mr-2"
                    color="amber darken-2"
                    v-if="verificarPermiso(26)"
                    @click="openModal(item,'editar')"                    
                  >
                  fas fa-edit
                </v-icon>
                </template>
                <span>Editar</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                   <v-icon
                    v-on="on"
                    small
                    color="red darken-2"
                    v-if="verificarPermiso(27)"
                    @click="deleteItem(item.id)"
                  >
                    fas fa-trash
                  </v-icon>
                </template>
                <span>Eliminar</span>
              </v-tooltip>

            </template>

          </v-data-table>
        </v-card>

      </div>
     
  </div>
</template>

<script>
import {mapMutations} from 'vuex'
import axios from 'axios'
import * as CONFIG from '@/config/config.js'
import * as checkPermiso from '@/config/checkPermisos.js'
export default {
  name:'roles',
  data(){
    return {
      items: [
                {
                    text: 'Inicio',
                    disabled: false,
                    exact: true,
                    to: {name: 'Home'}
                },
                {
                    text: 'Roles',
                    disabled: true,
                }
            ],      
      buscando: false,
      search:'',
      headers:[        
        { text: 'Id', value: 'id'},
        { text: 'Descripción', value: 'descripcion' },
        { text: 'Acciones', value: 'actions', sortable: false,width:'10%' }
      ],
      roles:[],
      rol:{
        descripcion:'',
        permisos:[]
      },
      dialog:false,
      tipo:'',
      dialogDelete:false,
      grupo_permisos:[]
    }
  },
  methods:{
     ...mapMutations(['setAlert','SnackbarShow']),
     verificarPermiso(rol){
          return checkPermiso.tienePermiso(rol);
    }, 
    getRoles() {

      return new Promise((resolve, reject) => {                    
           this.buscando = true;   
          axios.get(CONFIG.urlApi+'roles/getRoles',{})
          .then(resp => {
              
              this.roles = resp.data.data;
              this.buscando = false;
          })
          .catch(err => {
              
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
              });
              
              this.buscando = false;

          });
                    
      });
    },
    getRol(id) {

      return new Promise((resolve, reject) => {                    
           this.buscando = true;   
          axios.get(CONFIG.urlApi+'roles/getRol/'+id,{})
          .then(resp => {
            this.rol.permisos = [];
              this.rol = resp.data.data.rol;
              this.rol.permisos = resp.data.data.permisos;

              this.buscando = false;
              this.dialog = true;         
          })
          .catch(err => {                  
              
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
              });
              
              this.buscando = false;

          });
      });
    },
    getGrupoPermisos() {

      return new Promise((resolve, reject) => {                    
          this.buscando = true;   
          axios.get(CONFIG.urlApi+'grupoPermisos/getGrupoPermisos',{})
          .then(resp => {
              
              this.grupo_permisos = resp.data.data;
              this.buscando = false;
          })
          .catch(err => {
              
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
              });
              
              this.buscando = false;

          });
                    
      });
    },
    openModal(data,tipo) {

        this.tipo = tipo;
        this.rol = {
          descripcion:'',
          permisos:[]
        }
        
        if(tipo == 'editar'){
          //this.rol = data;
          this.getRol(data.id);
        }else{
          this.dialog = true;
        }
        
        
    },
    agregar(){

      return new Promise((resolve, reject) => {
            this.buscando = true;
            this.rol.nuevasOpciones = this.nuevaOpcion;
            let data_rol = this.rol;
            axios({url: CONFIG.urlApi+'roles/agregar',data:data_rol,method: 'POST' })
            .then(resp => {
            
              this.dialog = false;
              this.rol = {
                descripcion:'',
                permisos:[]
              }
              this.SnackbarShow({
                                    tipo: 'success',
                                    mensaje: resp.data.statusText
                                });

              this.getRoles();
              this.buscando = false;
              
          })
          .catch(err => {
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
                  });
              
              this.buscando = false;

          });
                    
      });

    },
    editar(){

      return new Promise((resolve, reject) => {
            this.buscando = true;
            axios({url: CONFIG.urlApi+'roles/editar/'+this.rol.id,data:this.rol,method: 'PUT' })
            .then(resp => {

              this.rol = {
                descripcion:'',
                permisos:[]
              }
              this.dialog = false;
              this.SnackbarShow({
                                    tipo: 'success',
                                    mensaje: resp.data.statusText
                                });

              this.getRoles();
              this.buscando = false;
              
          })
          .catch(err => {                  
              
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
                  });

              this.buscando = false;

          });
                    
      });

    },
    deleteItem (item_id) {
        this.rol.id = item_id;
        this.dialogDelete = true
    },
    agregarOpcionDinamica(){
      this.nuevaOpcion.push({
        nombre:'',
        accion:2
      });
    },
    eliminarOpcionDinamica(index){
      this.nuevaOpcion.splice(index, 1);
    },
    eliminarOpcion(id,indice){

      return new Promise((resolve, reject) => {
            this.buscando = true;            
            axios({url: CONFIG.urlApi+'opcionesPropiedades/eliminar/'+id,data:{},method: 'DELETE' })
            .then(resp => {

              this.rol.opciones.splice(indice, 1);              
              this.buscando = false;
              
          })
          .catch(err => {                  
              
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
                  });

          });
                    
      });

    },
    deleteItemConfirm () {
        
      return new Promise((resolve, reject) => {                    
          
          this.buscando = true;          
          
          axios.delete(CONFIG.urlApi+'roles/eliminar/'+this.rol.id, {})
          .then(resp => {                
             
              this.rol.id = {};

              this.SnackbarShow({
                                    tipo: 'success',
                                    mensaje: resp.data.statusText
                                });

              this.getRoles();

              this.buscando = false;
              
          })
          .catch(err => {                  
              
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
                  });

              this.buscando = false;

          });
            this.closeDelete();
                    
      });
    },
    closeDelete () {
      this.dialogDelete = false
    },
  },
  watch: {
      dialogDelete (val) {
        val || this.closeDelete()
      },
  },
  computed:{
    
  },
  created(){
    this.getRoles();
    this.getGrupoPermisos();
  }
  
}
</script>
