<template>
  <div>
    <v-breadcrumbs :items="items" class="pt-0">
        <template v-slot:divider>
            <v-icon :style="{'font-size': '10px'}">fas fa-chevron-right</v-icon>
        </template>
    </v-breadcrumbs>

      <div>       
        
        <v-card
        class="mx-auto"
        outlined
        elevation="10"
       max-width="1000"
        >

        <div v-if="buscando">
            <v-progress-linear
            indeterminate
            color="primary"
            ></v-progress-linear>
        </div>

          <v-data-table
            :headers="headers"
            :items="productos"
            :search="search"
          >
            <template v-slot:top>
              <v-toolbar
                flat
              >                 
                  <v-toolbar-title>Productos</v-toolbar-title>
                  <v-divider
                    class="mx-4"
                    inset
                    vertical
                  ></v-divider>
                  <v-spacer>
                  </v-spacer>
                    <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    hide-details
                    class="col-md-4 mr-6"
                  ></v-text-field>

                  <v-btn
                      color="success"
                      dark
                      class="mb-2"                      
                      elevation="12"                      
                      @click="openModal(null,'agregar')"
                      v-if="verificarPermiso(2)"
                    >
                    <v-icon>fas fa-plus </v-icon>
                  </v-btn>

                  <v-dialog
                    v-model="dialog"
                    persistent
                    max-width="600px"
                  >
                    <v-card>
                      <v-card-title>
                        <span class="text-h5" v-if="tipo == 'editar'">Editar Producto</span>
                        <span class="text-h5" v-if="tipo == 'agregar'">Agregar Producto</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>                           
                            <v-col md="12" xs="12">
                                  <v-text-field
                                  v-model="producto.nombre"
                                  label="Nombre"                   
                                  ></v-text-field>
                            </v-col>                            
                             <v-col md="12" xs="12">
                                <v-file-input
                                  v-model="producto.imagen"
                                  color="deep-purple accent-4"                                  
                                  label="Imagen"
                                  prepend-icon="fas fa-paperclip"                                 
                                >                                 
                                </v-file-input>
                            </v-col>
                          </v-row>
                        </v-container>                       
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="dialog = false"
                        >
                          Cerrar
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          v-if="tipo == 'editar'"
                          @click="editar"
                        >
                          Editar
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          v-if="tipo == 'agregar'"
                          @click="agregar"
                        >
                          Agregar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <v-dialog v-model="dialogDelete" max-width="550">
                    <v-card>
                      <v-toolbar
                        color="primary"
                        dark
                      >Eliminar
                      </v-toolbar>
                      <v-card-text class="text-h6 pa-10">Está seguro que desea eliminar el producto?</v-card-text>
                      <v-card-actions  class="justify-end">
                        <v-spacer></v-spacer>
                        <v-btn color="darken-1" text @click="closeDelete">Cancelar</v-btn>
                        <v-btn color="darken-1" class="primary" primary text @click="deleteItemConfirm">Confirmar</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

              </v-toolbar>
            </template>

            <template v-slot:item.imagen="props">
              <template>
                  <div class="pt-5 pb-5">
                   <v-img                    
                    max-height="114"
                    max-width="114"
                    :src="urlImg + props.item.imagen"
                    ></v-img>
                  </div>  

              </template>                
            </template>

            <template v-slot:item.actions="{ item }">              
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                   <v-icon
                    v-on="on"
                    small
                    class="mr-3"
                    color="amber darken-2"
                    @click="openModal(item,'editar')"
                    v-if="verificarPermiso(3)"
                  >
                  fas fa-edit
                </v-icon>
                </template>
                <span>Editar</span>
              </v-tooltip>

               <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                   <v-icon
                    v-on="on"
                    small
                    class="mr-3"
                    color="light-blue darken-2"
                    @click="configurar(item.id)"
                    v-if="verificarPermiso(4)"
                  >
                  fas fa-cogs
                </v-icon>
                </template>
                <span>Configurar</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                   <v-icon
                    v-on="on"
                    small
                    class="mr-3"
                    color="primary darken-2"
                    @click="cotizar(item.id)"
                    v-if="verificarPermiso(5)"
                  >
                  fas fa-dollar-sign
                </v-icon>
                </template>
                <span>Cotizar</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                   <v-icon
                    v-on="on"
                    small
                    color="red darken-2"
                    @click="deleteItem(item.id)"
                    v-if="verificarPermiso(6)"
                  >
                    fas fa-trash
                  </v-icon>
                </template>
                <span>Eliminar</span>
              </v-tooltip>

            </template>

          </v-data-table>          
        </v-card>

      </div>
     
  </div>
</template>

<script>
import {mapMutations} from 'vuex'
import axios from 'axios'
import * as CONFIG from '@/config/config.js'
import * as checkPermiso from '@/config/checkPermisos.js'
export default {
  name:'productos',
  data(){
    return {      
      items: [
                {
                    text: 'Inicio',
                    disabled: false,
                    exact: true,
                    to: {name: 'Home'}
                },
                {
                    text: 'Productos',
                    disabled: true,
                }
            ],      
      buscando: false,
      search:'',
      headers:[        
        { text: 'Nombre', value: 'nombre'},
        { text: 'Imagen', value: 'imagen' },
        { text: 'Acciones', value: 'actions', sortable: false,width:'15%' }
      ],
      productos:[],
      producto:{},
      dialog:false,
      tipo:'',
      dialogDelete:false,
      urlImg:CONFIG.urlImg
    }
  },
  methods:{
     ...mapMutations(['setAlert','SnackbarShow']),
    verificarPermiso(permiso){         
          return checkPermiso.tienePermiso(permiso);
    }, 
    getProductos() {
      
      return new Promise((resolve, reject) => {                    
            this.buscando = true;  
            axios.get(CONFIG.urlApi+'productos/getProductos',{})
          .then(resp => {
              
              this.productos = resp.data.data;
              this.buscando = false;

          })
          .catch(err => {
            
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
                  });
              this.buscando = false;

          });
                    
      });
    },
    cotizar(id) {
      this.$router.push({name:'CotizarProductoId',params: {'producto_id':id} } )
    },
    openModal(data,tipo) {

        this.tipo = tipo;
        this.producto = {};
        
        if(tipo == 'editar'){
          this.producto = data;
        }
        
        this.dialog = true;
    },
    agregar(){

      return new Promise((resolve, reject) => {
            
            this.buscando = true;
            let formData = new FormData();
            // Append values to the formData variable.
            formData.append('nombre', this.producto.nombre);
            formData.append('imagen', this.producto.imagen);

            axios.post(CONFIG.urlApi+'productos/agregar', formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                }
              )
            .then(resp => {
              
              this.producto = {};
              this.dialog = false;
              
              this.SnackbarShow({
                                    tipo: 'success',
                                    mensaje: resp.data.statusText
                                });
              
              this.getProductos();
              this.buscando = false;
              
          })
          .catch(err => {
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
                  });
              this.buscando = false;
          });
                    
      });

    },
    editar(){

      return new Promise((resolve, reject) => {
            
            this.buscando = true;
            let formData = new FormData();
            // Append values to the formData variable.
            formData.append('nombre', this.producto.nombre);
            formData.append('imagen', this.producto.imagen);
            formData.append('id', this.producto.id);

            let data_producto = this.producto;
            axios.post(CONFIG.urlApi+'productos/editar', formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                }
              )
            .then(resp => {
              
              this.producto = {};
              this.dialog = false;
              
              this.SnackbarShow({
                                    tipo: 'success',
                                    mensaje: resp.data.statusText
                                });
              
              this.getProductos();
              this.buscando = false;
              
          })
          .catch(err => {
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
                  });
              this.buscando = false;
          });
                    
      });

    },
    cotizar(id) {
        this.$router.push({name:'CotizarProductoId',params: {'producto_id':id} } )  
        
    },
    configurar(id) {
        this.$router.push({name:'ConfigurarProducto',params: {'producto_id':id} } )  
        
    },
    deleteItem (item_id) {
        this.producto.id = item_id;
        this.dialogDelete = true
    },
    deleteItemConfirm () {
        
      return new Promise((resolve, reject) => {                    
          this.buscando = true;       
          axios.delete(CONFIG.urlApi+'productos/eliminar/'+this.producto.id, {})
          .then(resp => {                
             
              this.producto.id = {};

              this.SnackbarShow({
                                    tipo: 'success',
                                    mensaje: resp.data.statusText
                                });

              this.getProductos();
              this.buscando = false;
              
          })
          .catch(err => {                  
              
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
                  });
              this.buscando = false;

          });
            this.closeDelete();
                    
      });
    },
    closeDelete () {
      this.dialogDelete = false
    },
  },
  watch: {
      dialogDelete (val) {
        val || this.closeDelete()
      },
  },
  mounted(){
    this.getProductos();
  }
  
}
</script>
