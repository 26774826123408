<template>
    <div>
        <v-card
        class="mx-auto"
        outlined
        elevation="10"
        >
          <v-data-table
            :headers="headers"
            :items="piezas_cotizacion"
            :search="search"
            :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50,-1]
            }"
            :items-per-page="30"
          >
            <template v-slot:top>
              <v-toolbar
                flat
              >                 
                  <v-toolbar-title>Piezas</v-toolbar-title>
                  <v-divider
                    class="mx-4"
                    inset
                    vertical
                  ></v-divider>

                  <v-spacer>
                  </v-spacer>

                    <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    hide-details
                    class="col-md-4 mr-6"
                  ></v-text-field>

              </v-toolbar>
            </template>


                <template slot="body.append" v-if="ver_precio">
                    <tr class="pink--text">
                        <th class="title">TOTAL</th>
                        <th class="title"></th>
                        <th class="title"></th>
                        <th class="title"></th>
                        <th class="title">{{ this.piezas.total }}</th>
                    </tr>
                </template>

          </v-data-table>          
        </v-card>

    </div>

</template>
<script>
export default {
    name:'PiezasCotizacion',
    props:{
        ver_precio:[Boolean],
        piezas:[Object,Array]
    },
    data(){
        return{            
            search:'',
            headers:[
                { text: 'Código', value: 'codigo' },
                { text: 'Nombre', value: 'nombre' },
                { text: 'Cantidad', value: 'cantidad'},                
            ],
            piezas_cotizacion:[]
        }
    },
    watch:{
        piezas: {
            handler(val,old) {              
               this.inicializarData();
            },
            deep: true
        },
    },
    methods:{
        inicializarData(){
          this.piezas_cotizacion = [];
          let data_piezas = this.piezas.piezas;
        
           for(let j in data_piezas){
            
              let data = new Array();

              data = {
                  nombre:data_piezas[j].nombre,
                  codigo:data_piezas[j].codigo,
                  precio_unitario:data_piezas[j].precio_unitario,
                  precio_total_pieza:data_piezas[j].precio_total_pieza,
                  cantidad:data_piezas[j].cantidad,
                };
                
                this.piezas_cotizacion.push(data);
           }


      },
    },
    created(){
          if(this.ver_precio){
            this.headers.push({ text: 'Precio', value: 'precio_unitario' });
            this.headers.push({ text: 'Precio total', value: 'precio_total_pieza' });
          }
    },
}
</script>