<template>
    <div>
        <v-breadcrumbs :items="items" class="pt-0">
            <template v-slot:divider>
                <v-icon :style="{'font-size': '10px'}">fas fa-chevron-right</v-icon>
            </template>
        </v-breadcrumbs>

       

        <v-card class="mx-auto" outlined elevation="10">
            <div v-if="buscando">
                <v-progress-linear
                indeterminate
                color="primary"
                ></v-progress-linear>
            </div>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="headline mb-3">Configuración - {{dataProducto.producto.nombre}}
                        
                    </v-list-item-title>
                         <v-tabs
                            fixed-tabs
                            slider-color="white"
                            >
                            <v-tab           
                                href="#tab-propiedades"
                            >
                            Propiedades
                            </v-tab>
                            <v-tab           
                                href='#tap-piezas'
                            >
                            Piezas
                            </v-tab>
                        
                            <v-tab-item                                
                                value="tab-propiedades"
                            >
                                <v-card flat>
                                    <v-card-text>
                                        <v-row>                            
                                            <v-col xs="12" md="12">
                                                <div v-if="buscando">
                                                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                                                </div>
                                                <v-card class="mx-auto mb-2" outlined elevation="10">                                    
                                                    <v-list-item>
                                                        <v-list-item-content>                                                           
                                                                <v-row>                            
                                                                    <v-col cols="12" md="12">
                                                                       <v-autocomplete
                                                                        label="Propiedades"  
                                                                        :items="optionPropiedades"                                                                                     
                                                                        :rules="[v => !!v || 'Debe seleccionar un producto']"
                                                                        required
                                                                        multiple
                                                                        chips
                                                                        deletable-chips
                                                                        v-model="productoPropiedadesModel.producto_propiedades"
                                                                        ></v-autocomplete>
                                                                    </v-col>
                                                                    <v-col cols="12" md="12" class="text-right">                                                                      
                                                                           <v-btn                          
                                                                            color="success"
                                                                            depressed
                                                                            elevation="2" 
                                                                            @click="guardarPropiedades()"            
                                                                            >
                                                                                <v-icon
                                                                                class="mr-1"> fas fa-save</v-icon>
                                                                                    Guardar                 
                                                                            </v-btn>

                                                                    </v-col>
                                                                </v-row>                                               
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-card>
                                            </v-col>                                                                                   
                                        </v-row>               

                                        <v-col xs="12" md="12">
                                            <v-row>
                                                <template v-if="dataProducto.producto.producto_propiedades.length">
                                                    
                                                    <v-col xs="12" md="3" v-for="(producto_propiedad,pp_index) in dataProducto.producto.producto_propiedades" :key="pp_index">
                                                        <v-card>
                                                            <v-card-title class="text-h6">
                                                                {{producto_propiedad.descripcion_propiedad.nombre}} ({{(producto_propiedad.descripcion_propiedad.tipo == 10) ? 'Opciones' : 'Rango'}})
                                                            </v-card-title>                                                            
                                                            <v-card-text>                                                                
                                                                <template v-if="dataProducto.array_opciones_propiedades[producto_propiedad.propiedad_id] && dataProducto.array_opciones_propiedades[producto_propiedad.propiedad_id].length">
                                                                    <v-checkbox
                                                                    v-for="(opciones,idx) of dataProducto.array_opciones_propiedades[producto_propiedad.propiedad_id]"
                                                                    v-model="productoPropiedadesModel.opciones"
                                                                    :input-value="dataProducto.array_pluck_opciones_del_producto.includes(opciones.id)"
                                                                    :label="opciones.nombre"
                                                                    color="indigo darken-3"
                                                                    :value="opciones.id"
                                                                    :key="idx"
                                                                    hide-details
                                                                    @change="updateOpcionProducto(opciones.id,producto_propiedad.propiedad_id)"
                                                                    ></v-checkbox>                                                                   
                                                                </template>
                                                            </v-card-text>
                                                            <v-card-actions>
                                                                <!--v-col cols="12" md="12">
                                                                <v-row>
                                                                    <v-col cols="12" md="9">
                                                                        <v-text-field label="Descripción" outlined dense
                                                                        :disabled="cargando_seccion" :rules="[v => !!v || 'Debe ingresar la descripción']"
                                                                        required></v-text-field>
                                                                    </v-col>
                                                                    <v-col cols="12" md="3">
                                                                        <v-btn color="success" type="submit"><i class="fas fa-plus"></i></v-btn>
                                                                    </v-col>
                                                                </v-row>
                                                                </v-col-->
                                                            </v-card-actions>
                                                        </v-card>
                                                    </v-col>
                                                </template>                                                
                                            </v-row>
                                        </v-col>
                                           
                                    </v-card-text>
                                </v-card>
                            </v-tab-item>

                            <v-tab-item                                
                                value="tap-piezas"
                            >
                               
                               <v-card flat>
                                    <v-card-text>
                                        <v-row>                            
                                            <v-col xs="12" md="12">
                                                <div v-if="buscando">
                                                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                                                </div>
                                                <v-card class="mx-auto mb-2" outlined elevation="10">                                    
                                                    <v-list-item>
                                                        <v-list-item-content>                                                           
                                                                <v-row>                            
                                                                    <v-col cols="12" md="12">
                                                                       <v-autocomplete
                                                                        label="Piezas"  
                                                                        :items="optionPiezas"                                                                                     
                                                                        :rules="[v => !!v || 'Debe seleccionar un producto']"
                                                                        required
                                                                        multiple
                                                                        chips
                                                                        deletable-chips
                                                                        v-model="productoPropiedadesModel.piezas"
                                                                        ></v-autocomplete>
                                                                    </v-col>
                                                                    <v-col cols="12" md="12" class="text-right">                                                                      
                                                                           <v-btn                          
                                                                            color="success"
                                                                            depressed
                                                                            elevation="2" 
                                                                            @click="guardarPiezas()"            
                                                                            >
                                                                                <v-icon
                                                                                class="mr-1"> fas fa-save</v-icon>
                                                                                    Guardar                 
                                                                            </v-btn>

                                                                    </v-col>
                                                                </v-row>                                               
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-card>
                                            </v-col>                                                                                   
                                        </v-row>               

                                        <v-row>                            
                                            <v-col xs="12" md="12">
                                                
                                                <v-data-table              
                                                    :headers="headers"
                                                    :items="config_piezas"                                                    
                                                    item-key="producto_pieza_id"
                                                    :expanded.sync="expanded"
                                                    class="elevation-1"
                                                    :search="search"                                                   
                                                    show-expand
                                                    :footer-props="{
                                                    'items-per-page-options': [10, 20, 30, 40, 50,-1]
                                                    }"
                                                    :items-per-page="30"
                                                >

                                                <template v-slot:top="{ pagination, options, updateOptions }">
                                                    <v-toolbar
                                                    flat
                                                    >                 
                                                        <v-toolbar-title>Configuración de piezas</v-toolbar-title>
                                                    
                                                        <v-divider
                                                            class="mx-4"
                                                            inset
                                                            vertical                                                            
                                                        ></v-divider>
                                                        <div class="mx-2">
                                                            <v-btn small color="info" @click="expandAll">Ver detalle piezas</v-btn>
                                                        </div><div class="mx-2">
                                                            <v-btn small color="primary" @click="collapseAll">Ocultar detalle piezas</v-btn>
                                                        </div>
                                                    

                                                        <v-divider
                                                            class="mx-4"
                                                            inset
                                                            vertical
                                                        ></v-divider>


                                                            <v-spacer>
                                                            </v-spacer>

                                                            <v-btn
                                                                color="success"
                                                                dark
                                                                class="mb-2"                      
                                                                elevation="12"                      
                                                                @click="guardarConfiguracionPiezas"        
                                                                >
                                                                <v-icon>fas fa-save </v-icon>
                                                            </v-btn>

                                                            <v-text-field
                                                            v-model="search"
                                                            append-icon="mdi-magnify"
                                                            label="Buscar"
                                                            single-line
                                                            hide-details
                                                            class="col-md-3 mr-6"
                                                            ></v-text-field>

                                                    </v-toolbar>
                                                
                                                    <v-data-footer 
                                                    :pagination="pagination" 
                                                    :options="options"
                                                    @update:options="updateOptions"
                                                    :items-per-page-options="[10, 20, 30, 40, 50,-1]"
                                                    items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                                                    />

                                                </template>
                                                

                                                <!--DATA REGISTROS -->

                                                <!--template v-slot:item.activo="props">
                                                    
                                                    <template>                 
                                                            <v-chip
                                                            :color="props.item.activo == 1 ? 'success' : 'error' "
                                                            dark
                                                            >
                                                            {{ (props.item.activo == 1) ? 'Sí' : 'No' }}
                                                            </v-chip>
                                                    </template>
                                                    
                                                </template-->

                                                <!--FIN DATA REGISTROS -->

                                                <!--EXPAND REGISTRO -->
                                                <template v-slot:expanded-item="{ headers, item }">
                                                    <td :colspan="headers.length" class="pb-7 pt-7">
                                                        <div class="row">
                                                            <div class="col">
                                                                <div align="center">
                                                                    <v-simple-table style="width:90%">
                                                                    <template v-slot:default>
                                                                        <thead class="light-blue darken-2">
                                                                        <th style="width:15%">Cantidad</th>
                                                                        <th>Propiedad</th>
                                                                        <th>Atributos</th>
                                                                        <th>D</th>
                                                                        <th>H</th>
                                                                        <th>
                                                                            <v-btn
                                                                            color="success"
                                                                            dark
                                                                            class="mb-2 mt-2"                      
                                                                            elevation="12"
                                                                            @click="agregarPiezaConfiguracion(item.producto_pieza_pp_id)"                                                                            
                                                                            >
                                                                            <v-icon>fas fa-plus </v-icon>
                                                                        </v-btn>    
                                                                        </th>
                                                                        </thead>
                                                                        
                                                                            <tbody v-if="item.configuraciones.length > 0">
                                                                            <template v-for="(pieza_configuracion,index_config) in item.configuraciones">
                                                                                <tr class="text-center blue lighten-4"  :key="index_config">
                                                                                    
                                                                                    <td> 
                                                                                        <v-text-field
                                                                                            type="number"
                                                                                            v-model="pieza_configuracion.cantidad"
                                                                                            label="Cantidad"
                                                                                            class="font-weight-bold"
                                                                                            single-line
                                                                                            autofocus                                     
                                                                                        >
                                                                                        </v-text-field>
                                                                                    </td>
                                                                                    <td> 
                                                                                        <v-autocomplete
                                                                                        label="Propiedad"
                                                                                        :items="propiedadesActivasProducto"                                                                                    
                                                                                        v-model="pieza_configuracion.propiedad_id"                                                                                    
                                                                                        ></v-autocomplete>
                                                                                    </td>
                                                                                    <td>                                                                                 
                                                                                        <v-autocomplete
                                                                                        label="Atributo"
                                                                                        :items="opcionesPropiedadConfig[pieza_configuracion.propiedad_id]"
                                                                                        v-model="pieza_configuracion.opcion_propiedad_id"
                                                                                        ></v-autocomplete>
                                                                                    </td>
                                                                                    <td>                                                                                    
                                                                                        <template v-if="tipoPropiedad(pieza_configuracion.propiedad_id) == 20">
                                                                                            <v-text-field
                                                                                            type="number"
                                                                                            v-model="pieza_configuracion.rango_desde"
                                                                                            label="Desde"
                                                                                            class="font-weight-bold"
                                                                                            single-line
                                                                                            autofocus                                     
                                                                                            >
                                                                                            </v-text-field>
                                                                                        </template>                                                                                    
                                                                                    </td>
                                                                                    <td>
                                                                                        <template v-if="tipoPropiedad(pieza_configuracion.propiedad_id) == 20">
                                                                                            <v-text-field
                                                                                            type="number"
                                                                                            v-model="pieza_configuracion.rango_hasta"
                                                                                            label="Hasta"
                                                                                            class="font-weight-bold"
                                                                                            single-line
                                                                                            autofocus
                                                                                            >
                                                                                            </v-text-field>
                                                                                        </template>
                                                                                    </td>
                                                                                    <td>
                                                                                        <v-tooltip bottom>
                                                                                            <template v-slot:activator="{ on }">
                                                                                            <v-icon
                                                                                                v-on="on"                                                                                                
                                                                                                small
                                                                                                class="mr-2"
                                                                                                color="primary darken-2"
                                                                                                @click="agregarPiezaCombinacion(item.producto_pieza_pp_id,index_config)"                                                                                     
                                                                                            >
                                                                                                fas fa-exchange-alt
                                                                                            </v-icon>
                                                                                            </template>
                                                                                            <span>Eliminar</span>
                                                                                        </v-tooltip>

                                                                                        <v-tooltip bottom>
                                                                                            <template v-slot:activator="{ on }">
                                                                                            <v-icon
                                                                                                v-on="on"
                                                                                                small
                                                                                                color="red darken-2"
                                                                                                @click="eliminarPiezaConfiguracion(item.producto_pieza_pp_id,index_config)"                                                                                     
                                                                                            >
                                                                                                fas fa-trash
                                                                                            </v-icon>
                                                                                            </template>
                                                                                            <span>Eliminar</span>
                                                                                        </v-tooltip>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr class="text-center"  v-for="(pieza_combinables,index_combinables) in pieza_configuracion.combinables">
                                                                                    
                                                                                    <td> 
                                                                                        <v-text-field
                                                                                            type="number"
                                                                                            v-model="pieza_combinables.cantidad"
                                                                                            label="Cantidad C"
                                                                                            class="font-weight-bold"
                                                                                            single-line
                                                                                            autofocus
                                                                                            disabled
                                                                                        >
                                                                                        </v-text-field>
                                                                                    </td>
                                                                                    <td> 
                                                                                        <v-autocomplete
                                                                                        label="Propiedad C"
                                                                                        :items="propiedadesActivasProducto"                                                                                    
                                                                                        v-model="pieza_combinables.propiedad_id"                                                                                    
                                                                                        ></v-autocomplete>
                                                                                    </td>
                                                                                    <td>                                                                                 
                                                                                        <v-autocomplete
                                                                                        label="Atributo"
                                                                                        :items="opcionesPropiedadConfig[pieza_combinables.propiedad_id]"
                                                                                        v-model="pieza_combinables.opcion_propiedad_id"
                                                                                        ></v-autocomplete>
                                                                                    </td>
                                                                                    <td>                                                                                    
                                                                                        <template v-if="tipoPropiedad(pieza_combinables.propiedad_id) == 20">
                                                                                            <v-text-field
                                                                                            type="number"
                                                                                            v-model="pieza_combinables.rango_desde"
                                                                                            label="Desde"
                                                                                            class="font-weight-bold"
                                                                                            single-line
                                                                                            autofocus                                     
                                                                                            >
                                                                                            </v-text-field>
                                                                                        </template>                                                                                    
                                                                                    </td>
                                                                                    <td>
                                                                                        <template v-if="tipoPropiedad(pieza_combinables.propiedad_id) == 20">
                                                                                            <v-text-field
                                                                                            type="number"
                                                                                            v-model="pieza_combinables.rango_hasta"
                                                                                            label="Hasta"
                                                                                            class="font-weight-bold"
                                                                                            single-line
                                                                                            autofocus
                                                                                            >
                                                                                            </v-text-field>
                                                                                        </template>
                                                                                    </td>
                                                                                    <td>                                                                                        
                                                                                        <v-tooltip bottom>
                                                                                            <template v-slot:activator="{ on }">

                                                                                                <v-icon
                                                                                                    v-on="on"
                                                                                                    small
                                                                                                    color="red darken-2"
                                                                                                    @click="eliminarPiezaCombinable(item.producto_pieza_pp_id,index_config,index_combinables)"                                                                                     
                                                                                                >
                                                                                                    fas fa-trash
                                                                                                </v-icon>
                                                                                            </template>
                                                                                            <span>Eliminar</span>
                                                                                        </v-tooltip>
                                                                                    </td>
                                                                                </tr>
                                                                            </template>
                                                                            </tbody>
                                                                            <tbody v-else>
                                                                                <tr>
                                                                                    <td colspan="5" align="center">NO HAY DATOS</td>
                                                                                </tr>
                                                                            </tbody>
                                                                    </template>
                                                                    </v-simple-table>
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </template>
                                                <!-- FIN EXPAND REGISTRO -->

                                                </v-data-table>

                                            </v-col>                                                                                   
                                        </v-row>    

                                    </v-card-text>
                                </v-card>
                               
                            </v-tab-item>

                         </v-tabs>
  
                </v-list-item-content>
            </v-list-item>
        </v-card>

        <v-dialog
        v-model="dialogValidacion"
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-title class="mb-5">
            <span class="text-h5">Errores de validación</span>
          </v-card-title>
          <v-card-text>
           <errores-validacion :errors="validationErrors" v-if="validationErrors"></errores-validacion>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialogValidacion = false"
            >
              Cerrar
            </v-btn>            
          </v-card-actions>
        </v-card>
      </v-dialog>

    </div>
</template>

<script>
import {mapActions,mapMutations} from 'vuex'
import axios from 'axios'
import * as CONFIG from '@/config/config.js'
import * as checkPermiso from '@/config/checkPermisos.js'
import ErroresValidacion from '@/components/ErroresValidacion.vue'
export default{
    name: 'ConfigurarProducto',
    data: () => ({
        tipo_vista:'',
        items: [
            {
                text: 'Inicio',
                disabled: false,
                exact: true,
                to: {name: 'Home'}
            },
            {
                text: 'Productos',
                disabled: false,
                exact: true,
                to: {name: 'Productos'}
            },
            {
                text: 'Configurar',
                disabled: true,
            }
        ],
        tabSimulacion:'tab-propiedades',
        buscando: false,
        dataProducto:{
            producto:{
                producto_propiedades:[],
            }
        },
        propiedades:[],
        piezas:[],
        productoPropiedadesModel:{
            opciones:[]
        },
        dialogValidacion:false,
        validationErrors:'',
        search:'',
        headers:[
            {text:'Código',value:'codigo',align:'center'}, 
            {text:'Nombre',value:'nombre',align:'center'}
        ],
        expanded:[],
        config_piezas:[],
        opcionesPropiedadConfig:[],
        opcionesPropiedadCombinacion:[],
        propiedadesActivasProducto : []
        
    }),
    components: {

        ErroresValidacion

    },
    methods:{
            ...mapMutations(['SnackbarShow']),
        verificarPermiso(permiso){
            return checkPermiso.tienePermiso(permiso);
        },
        /**
         * Trae la información de la simulación que será editada
         */
        getProducto(){
            this.buscando = true;

            return new Promise((resolve, reject) => {                    
            let producto_id = this.dataProducto.producto.id;
            this.buscando = true;  
            axios.get(CONFIG.urlApi+'productos/getProducto/'+producto_id,{})
                .then(resp => {
                    
                    this.dataProducto = resp.data.data;
                    this.productoPropiedadesModel.producto_propiedades = this.dataProducto.producto_propiedades;
                    this.productoPropiedadesModel.opciones = this.dataProducto.array_pluck_opciones_del_producto;
                    this.productoPropiedadesModel.piezas = this.dataProducto.producto_piezas;
                    this.buscando = false;

                    this.inicializarConfiguracionPiezas();
                    
                    this.optionPropiedadesProducto();
                    this.opcionesPropiedadConfiguraciones();
                    

                })
                .catch(err => {                  
                    
                    this.SnackbarShow({
                            tipo: 'error',
                            mensaje: err.statusText
                        });
                    this.buscando = false;

                });
                            
            });
            
        },
        getPropiedades() {

            return new Promise((resolve, reject) => {                    
                this.buscando = true;   
                axios.get(CONFIG.urlApi+'propiedades/getPropiedades',{})
                .then(resp => {
                    
                    this.propiedades = resp.data.data;
                    this.buscando = false;
                })
                .catch(err => {                  
                    
                    this.SnackbarShow({
                            tipo: 'error',
                            mensaje: err.statusText
                    });
                    
                    this.buscando = false;

                });
                            
            });
        },
        getPiezas() {

            return new Promise((resolve, reject) => {                    
                this.buscando = true;   
                axios.get(CONFIG.urlApi+'piezas/getPiezas',{})
                .then(resp => {
                    
                    this.piezas = resp.data.data;
                    this.buscando = false;
                })
                .catch(err => {                  
                    
                    this.SnackbarShow({
                            tipo: 'error',
                            mensaje: err.statusText
                    });
                    
                    this.buscando = false;

                });
                            
            });
        },
        updateOpcionProducto(opcion_id,propiedad_id){
            
            let checked = 'checked';
            if(this.dataProducto.array_pluck_opciones_del_producto.includes(opcion_id)){
                checked = 'unchecked';
            }

            let data = {
                producto_id:this.dataProducto.producto.id,
                propiedad_id:propiedad_id,
                opcion_propiedad_id:opcion_id,
                checked: checked
            }
            return new Promise((resolve, reject) => {
                this.buscando = true;
                axios.post(CONFIG.urlApi+'productos/configurarOpcionPropiedadAlProducto',data)
                .then(resp => {
                    
                    this.SnackbarShow({
                                    tipo: 'success',
                                    mensaje: resp.data.statusText
                                });
                    if(checked == 'checked'){
                        this.dataProducto.array_pluck_opciones_del_producto.push(opcion_id);
                    }else{
                        this.dataProducto.array_pluck_opciones_del_producto = this.dataProducto.array_pluck_opciones_del_producto.filter(opcion => opcion != opcion_id);
                    }
                    this.buscando = false;
                })
                .catch(err => {          
                    
                    if(err.validacion){
                        this.validationErrors = err.validacion;
                        this.dialogValidacion = true;
                    }
                    
                    this.SnackbarShow({
                            tipo: 'error',
                            mensaje: err.statusText
                    });
                    
                    this.buscando = false;

                });
                            
            });
        },
        guardarPropiedades(){
            
            let data = {
                id:this.dataProducto.producto.id,
                propiedades:this.productoPropiedadesModel.producto_propiedades,
                nombre:this.dataProducto.producto.nombre               
            }
            return new Promise((resolve, reject) => {                    
                this.buscando = true;
                axios.post(CONFIG.urlApi+'productos/editar',data)
                .then(resp => {
                    
                    this.SnackbarShow({
                                    tipo: 'success',
                                    mensaje: resp.data.statusText
                                });
                    this.getProducto();

                    this.buscando = false;
                })
                .catch(err => {          
                    
                    if(err.validacion){
                        this.validationErrors = err.validacion;
                        this.dialogValidacion = true;
                    }
                    
                    this.SnackbarShow({
                            tipo: 'error',
                            mensaje: err.statusText
                    });
                    
                    this.buscando = false;

                });
                            
            });
        },
        guardarPiezas(){
            
            let data = {
                producto_id:this.dataProducto.producto.id,
                piezas:this.productoPropiedadesModel.piezas
            }
            return new Promise((resolve, reject) => {                    
                this.buscando = true;
                axios.post(CONFIG.urlApi+'productos/configurarPiezasAlProducto',data)
                .then(resp => {
                    
                    this.SnackbarShow({
                                    tipo: 'success',
                                    mensaje: resp.data.statusText
                                });
                    this.getProducto();

                    this.buscando = false;
                })
                .catch(err => {          
                    
                    if(err.validacion){
                        this.validationErrors = err.validacion;
                        this.dialogValidacion = true;
                    }
                    
                    this.SnackbarShow({
                            tipo: 'error',
                            mensaje: err.statusText
                    });
                    
                    this.buscando = false;

                });
                            
            });
        },
        inicializarConfiguracionPiezas(){

        console.log('inicializar config piezas');
          this.config_piezas = [];           
          let data_piezas = this.dataProducto.producto_piezas_array_asociativo;

           for(let j in data_piezas){

              let data = new Array();
              data = {
                        nombre:                 data_piezas[j]['info'].nombre,
                        codigo:                 data_piezas[j]['info'].codigo,
                        producto_pieza_id:      data_piezas[j]['info'].producto_pieza_id,
                        producto_pieza_pp_id:   data_piezas[j]['info'].producto_pieza_pp_id,
                        configuraciones:        data_piezas[j]['configuraciones']
                    };


                this.config_piezas.push(data);
                
                
           }

        },
        expandAll() {      
            this.expanded = this.config_piezas;      
        },
        collapseAll() {
            this.expanded = [];
        },
        tipoPropiedad(propiedad_id){

          if(propiedad_id != ''){
            let event =  this.propiedades.find((propiedad,ind) => {
                if(propiedad.id == propiedad_id){
                    return propiedad;
                }
            });
            return event.tipo;
          }else{
            return 0;
          }

        },
        opcionesPropiedadConfiguraciones(){
            
            let  data = Object.entries(this.dataProducto.array_opciones_propiedades);
            this.opcionesPropiedadConfig = [];
            for(let i in data){
                let data_propiedad_id = data[i][0];             
                let data_propiedad_opciones = data[i][1];
                
                for(let j in data_propiedad_opciones){
                    if(!this.opcionesPropiedadConfig[data_propiedad_id]){
                        this.opcionesPropiedadConfig[data_propiedad_id] = new Array();
                    }
                    this.opcionesPropiedadConfig[data_propiedad_id].push(
                        {value : data_propiedad_opciones[j].id,
                        text : data_propiedad_opciones[j].nombre}
                    );
                }

            }

        },
        opcionesPropiedadCombinaciones(propiedad_id,index){
            let opciones = [];
            if(propiedad_id != ''){
                if( this.dataProducto.array_opciones_propiedades[propiedad_id] && this.dataProducto.array_opciones_propiedades[propiedad_id].length){

                    
                    let array_opciones = this.dataProducto.array_opciones_propiedades[propiedad_id];
                    for(let j in array_opciones){

                        opciones.push({value:array_opciones[j].id,text:array_opciones[j].nombre});

                    }
                }
                
            }
            this.opcionesPropiedadComb[index] = opciones;             

        },
        optionPropiedadesProducto(){

            this.propiedadesActivasProducto = [];
            this.propiedades.map((propiedad,ind) => {
                
                if(this.dataProducto.producto_propiedades.includes(propiedad.id)){
                   this.propiedadesActivasProducto.push({value:propiedad.id,text:propiedad.nombre});
                }
            });
           
        },
        agregarPiezaConfiguracion(pieza_id){

            for (let i in this.config_piezas) {               
                if (this.config_piezas[i].producto_pieza_pp_id == pieza_id) {

                    this.config_piezas[i].configuraciones.push({
                        id : '',
                        producto_id : '',
                        pieza_id : '',
                        opcion_propiedad_id : '',
                        rango_desde : '',
                        rango_hasta : '',
                        cantidad : '',
                        combina : '',
                        combina_editar : '',
                        propiedad_id : '',
                        propiedad_tipo : '',
                        combinables:[]
                    });

                }
            }

        },     
        
        eliminarPiezaConfiguracion(pieza_id,idx){          

          for (let i in this.config_piezas) {               
                if (this.config_piezas[i].producto_pieza_pp_id == pieza_id) {

                    this.config_piezas[i].configuraciones.splice(idx, 1);

                }
            }

        },
        agregarPiezaCombinacion(pieza_id,indice_pieza_configuracion){        

            for (let i in this.config_piezas) {               
                if (this.config_piezas[i].producto_pieza_pp_id == pieza_id) { 
                    console.log(this.config_piezas[i].configuraciones[indice_pieza_configuracion]); 
                    
                    
                    
                    this.config_piezas[i].configuraciones[indice_pieza_configuracion].combinables.push({
                        id : '',
                        producto_id : '',
                        pieza_id : '',
                        opcion_propiedad_id : '',
                        rango_desde : '',
                        rango_hasta : '',
                        cantidad : '',
                        combina : '',
                        combina_editar : '',
                        propiedad_id : '',
                        propiedad_tipo : ''
                    });
                }
            }

        },
        eliminarPiezaCombinable(pieza_id,indice_pieza_configuracion,idx_combinable){          

            for (let i in this.config_piezas) {               
                if (this.config_piezas[i].producto_pieza_pp_id == pieza_id) {                   
                    this.config_piezas[i].configuraciones[indice_pieza_configuracion].combinables.splice(idx_combinable, 1);
                }
            }

        },
        guardarConfiguracionPiezas(){
            
            let data = {
                producto_id:this.dataProducto.producto.id,
                opciones_configuracion:this.config_piezas
            }
            return new Promise((resolve, reject) => {                    
                this.buscando = true;
                axios.post(CONFIG.urlApi+'productos/configurarCombinacionesPiezasAlProducto',data)
                .then(resp => {
                    
                    this.SnackbarShow({
                                    tipo: 'success',
                                    mensaje: resp.data.statusText
                                });
                    this.getProducto();

                    this.buscando = false;
                })
                .catch(err => {          
                    
                    if(err.validacion){
                        this.validationErrors = err.validacion;
                        this.dialogValidacion = true;
                    }
                    
                    this.SnackbarShow({
                            tipo: 'error',
                            mensaje: err.statusText
                    });
                    
                    this.buscando = false;

                });
                            
            });
        },
        
                    
    },
    created(){
        //verifico si existe el parametro para poder traer su información
        if(this.$route.params.producto_id){
            this.dataProducto.producto.id = this.$route.params.producto_id;
            this.getProducto();
            this.getPropiedades();
            this.getPiezas();
            
        }
    },
    computed:{
        optionPropiedades(){
            return this.propiedades.map((propiedad,key) => {
                return {value:propiedad.id,text:propiedad.nombre}
            });
        },
        optionPiezas(){
            return this.piezas.map((pieza,key) => {
                return {value:pieza.id,text:'('+pieza.codigo+') - '+pieza.nombre}
            });
        },
        
       
    }
}
</script>