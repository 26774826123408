<template>
  <div>
    <v-breadcrumbs :items="items" class="pt-0">
        <template v-slot:divider>
            <v-icon :style="{'font-size': '10px'}">fas fa-chevron-right</v-icon>
        </template>
    </v-breadcrumbs>

      <div>        
        
        <v-card
        class="mx-auto"
        outlined
        elevation="10"
       max-width="1000"
        >
        <div v-if="buscando">
            <v-progress-linear
            indeterminate
            color="primary"
            ></v-progress-linear>
        </div>
          <v-data-table
            :headers="headers"
            :items="permisos"
            :search="search"
          >
            <template v-slot:top>
              <v-toolbar
                flat
              >                 
                  <v-toolbar-title>Permisos</v-toolbar-title>
                  <v-divider
                    class="mx-4"
                    inset
                    vertical
                  ></v-divider>
                  <v-spacer>
                  </v-spacer>
                    <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    hide-details
                    class="col-md-4 mr-6"
                  ></v-text-field>

                  <v-btn
                      color="success"
                      dark
                      class="mb-2"                      
                      elevation="12"
                      v-if="verificarPermiso(33)"
                      @click="openModal(null,'agregar')"        
                    >
                    <v-icon>fas fa-plus </v-icon>
                  </v-btn>

                  <v-dialog
                    v-model="dialog"
                    persistent
                    max-width="600px"               
                  >                  
                    <v-card>
                      <v-card-title>
                        <span class="text-h5" v-if="tipo == 'editar'">Editar Permiso</span>
                        <span class="text-h5" v-if="tipo == 'agregar'">Agregar Permiso</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>                           
                            <v-col md="12" xs="12">
                                  <v-text-field
                                  v-model="permiso.descripcion"
                                  label="Nombre"                   
                                  ></v-text-field>
                            </v-col>                            
                            <v-col md="12" xs="12">
                              <v-autocomplete
                              label="Grupo de permiso"
                              :items="optionGrupoPermisos"                             
                              v-model="permiso.grupo_permiso_id"
                              ></v-autocomplete>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="dialog = false"
                        >
                          Cerrar
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          v-if="tipo == 'editar'"
                          @click="editar"
                        >
                          Editar
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          v-if="tipo == 'agregar'"
                          @click="agregar"
                        >
                          Agregar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <v-dialog v-model="dialogDelete" max-width="550">
                    <v-card>
                      <v-toolbar
                        color="primary"
                        dark
                      >Eliminar
                      </v-toolbar>
                      <v-card-text class="text-h6 pa-10">Está seguro que desea eliminar el permiso?</v-card-text>
                      <v-card-actions  class="justify-end">
                        <v-spacer></v-spacer>
                        <v-btn color="darken-1" text @click="closeDelete">Cancelar</v-btn>
                        <v-btn color="darken-1" class="primary" primary text @click="deleteItemConfirm">Confirmar</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.grupo_permiso="{ item }">              
              {{ item.grupo_permiso.descripcion }}
            </template>

            <template v-slot:item.actions="{ item }">              
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                   <v-icon
                    v-on="on"
                    small
                    class="mr-2"
                    color="amber darken-2"
                    v-if="verificarPermiso(34)"
                    @click="openModal(item,'editar')"                    
                  >
                  fas fa-edit
                </v-icon>
                </template>
                <span>Editar</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                   <v-icon
                    v-on="on"
                    small
                    color="red darken-2"
                    v-if="verificarPermiso(35)"
                    @click="deleteItem(item.id)"                    
                  >
                    fas fa-trash
                  </v-icon>
                </template>
                <span>Eliminar</span>
              </v-tooltip>

            </template>

          </v-data-table>
        </v-card>

      </div>
     
  </div>
</template>

<script>
import {mapMutations} from 'vuex'
import axios from 'axios'
import * as CONFIG from '@/config/config.js'
import * as checkPermiso from '@/config/checkPermisos.js'
export default {
  name:'permisos',
  data(){
    return {
      items: [
                {
                    text: 'Inicio',
                    disabled: false,
                    exact: true,
                    to: {name: 'Home'}
                },
                {
                    text: 'Permisos',
                    disabled: true,
                }
            ],      
      buscando: false,
      search:'',
      headers:[        
        { text: 'Id', value: 'id'},
        { text: 'Descripción', value: 'descripcion' },
        { text: 'Grupo', value: 'grupo_permiso' },
        { text: 'Acciones', value: 'actions', sortable: false,width:'10%' }
      ],
      permisos:[],
      permiso:{},
      dialog:false,
      tipo:'',
      dialogDelete:false,
      grupo_permisos:[]
    }
  },
  methods:{
     ...mapMutations(['setAlert','SnackbarShow']),
    verificarPermiso(permiso){
          return checkPermiso.tienePermiso(permiso);
    }, 
    getPermisos() {

      return new Promise((resolve, reject) => {                    
           this.buscando = true;   
          axios.get(CONFIG.urlApi+'permisos/getPermisos',{})
          .then(resp => {
              
              this.permisos = resp.data.data;
              this.buscando = false;
          })
          .catch(err => {
              
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
              });
              
              this.buscando = false;

          });
                    
      });
    },
    getPermiso(id) {

      return new Promise((resolve, reject) => {                    
           this.buscando = true;   
          axios.get(CONFIG.urlApi+'permisos/getPermiso/'+id,{})
          .then(resp => {
              
              this.permiso = resp.data.data;
              this.buscando = false;
          })
          .catch(err => {                  
              
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
              });
              
              this.buscando = false;

          });
                    
      });
    },
    getGrupoPermisos() {

      return new Promise((resolve, reject) => {                    
          this.buscando = true;   
          axios.get(CONFIG.urlApi+'grupoPermisos/getGrupoPermisos',{})
          .then(resp => {
              
              this.grupo_permisos = resp.data.data;
              this.buscando = false;
          })
          .catch(err => {
              
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
              });
              
              this.buscando = false;

          });
                    
      });
    },
    openModal(data,tipo) {

        this.tipo = tipo;
        this.permiso = {};
        
        if(tipo == 'editar'){
          //this.permiso = data;
          this.getPermiso(data.id);
        }
        
        this.dialog = true;
    },
    agregar(){

      return new Promise((resolve, reject) => {
            this.buscando = true;
            this.permiso.nuevasOpciones = this.nuevaOpcion;
            let data_permiso = this.permiso;
            axios({url: CONFIG.urlApi+'permisos/agregar',data:data_permiso,method: 'POST' })
            .then(resp => {
              
              this.permiso = {};
              this.dialog = false;
              
              this.SnackbarShow({
                                    tipo: 'success',
                                    mensaje: resp.data.statusText
                                });

              this.getPermisos();
              this.buscando = false;
              
          })
          .catch(err => {
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
                  });
              
              this.buscando = false;

          });
                    
      });

    },
    editar(){

      return new Promise((resolve, reject) => {
            this.buscando = true;
            axios({url: CONFIG.urlApi+'permisos/editar/'+this.permiso.id,data:this.permiso,method: 'PUT' })
            .then(resp => {

              this.permiso = {};
              this.dialog = false;
              this.SnackbarShow({
                                    tipo: 'success',
                                    mensaje: resp.data.statusText
                                });

              this.getPermisos();
              this.buscando = false;
              
          })
          .catch(err => {                  
              
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
                  });

              this.buscando = false;

          });
                    
      });

    },
    deleteItem (item_id) {
        this.permiso.id = item_id;
        this.dialogDelete = true
    },
    agregarOpcionDinamica(){
      this.nuevaOpcion.push({
        nombre:'',
        accion:2
      });
    },
    eliminarOpcionDinamica(index){
      this.nuevaOpcion.splice(index, 1);
    },
    eliminarOpcion(id,indice){

      return new Promise((resolve, reject) => {
            this.buscando = true;            
            axios({url: CONFIG.urlApi+'opcionesPropiedades/eliminar/'+id,data:{},method: 'DELETE' })
            .then(resp => {

              this.permiso.opciones.splice(indice, 1);              
              this.buscando = false;
              
          })
          .catch(err => {                  
              
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
                  });

          });
                    
      });

    },
    deleteItemConfirm () {
        
      return new Promise((resolve, reject) => {                    
          
          this.buscando = true;          
          
          axios.delete(CONFIG.urlApi+'permisos/eliminar/'+this.permiso.id, {})
          .then(resp => {                
             
              this.permiso.id = {};

              this.SnackbarShow({
                                    tipo: 'success',
                                    mensaje: resp.data.statusText
                                });

              this.getPermisos();

              this.buscando = false;
              
          })
          .catch(err => {                  
              
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
                  });

              this.buscando = false;

          });
            this.closeDelete();
                    
      });
    },
    closeDelete () {
      this.dialogDelete = false
    },
  },
  watch: {
      dialogDelete (val) {
        val || this.closeDelete()
      },
  },
  computed:{
    optionGrupoPermisos(){
        return this.grupo_permisos.map((grupo,key) => {
            return {value:grupo.id,text:grupo.descripcion}
        });
    },
  },
  created(){
    this.getPermisos();
    this.getGrupoPermisos();
  }
  
}
</script>
