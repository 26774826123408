<template>
  <div>
    <v-breadcrumbs :items="items" class="pt-0">
        <template v-slot:divider>
            <v-icon :style="{'font-size': '10px'}">fas fa-chevron-right</v-icon>
        </template>
    </v-breadcrumbs>

      <div>       
        
        <v-card
        class="mx-auto"
        outlined
        elevation="10"
        >

        <div v-if="buscando">
            <v-progress-linear
            indeterminate
            color="primary"
            ></v-progress-linear>
        </div>
        <v-card-title>
            Detalle       
        </v-card-title>
        <v-card-text>
          <v-row>
              <v-col cols="12" md="6">
                  
                  <v-row>

                    <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    >
                        <v-text-field
                            label="Descripción de la Obra"
                            v-model="cotizar.cliente.descripcion"
                            :rules="[v => !!v || 'Debe ingresar la descripción']"
                            required
                        ></v-text-field>
                    </v-col>

                    <v-col 
                    cols="12"
                    sm="12"
                    md="12"
                    >
                    <v-autocomplete                                              
                        dense
                        label="Cliente"
                        :items="optionClientes"
                        item-text="text"
                        item-value="value"
                        v-model="cotizar.cliente.id"                                
                        :hide-details="true"
                        @change="getDataCliente"                                                      
                        >
                    </v-autocomplete>
                    </v-col>
                    <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    >
                        <v-text-field
                            label="Nombre"
                            v-model="cotizar.cliente.nombre"
                        ></v-text-field>
                    </v-col>

                    <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    >
                        <v-text-field
                            label="Teléfono"
                            v-model="cotizar.cliente.telefono"
                        ></v-text-field>
                    </v-col>

                    <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    >
                        <v-text-field
                            label="Email"
                            v-model="cotizar.cliente.email"
                        ></v-text-field>
                    </v-col>
                    
                    <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    >
                        <v-text-field
                            label="Bonificación"
                            v-model="cotizar.cliente.bonificacion"
                            @change="getDataDetalleCarrito"
                        ></v-text-field>
                    </v-col>

                  </v-row>
                  <v-row>
                      <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      >
                      <Cart tipo='list'></Cart>
                    </v-col>
                  </v-row>

              </v-col>

              <v-col cols="12" md="6">
                  <v-row>
                      <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      class="text-center"
                      >
                        <detalle-precio-cotizacion-cart :detalle="detalle"></detalle-precio-cotizacion-cart>
                      </v-col>
                  </v-row>

                  <v-row>
                      <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      class="text-right"
                      >
                      <v-btn color="success" type="submit" @click="generarCotizacion"><i class="fas fa-check mx-1"></i> Generar cotización</v-btn>
                      </v-col>
                  </v-row>
                   
                   
              </v-col>                        

           
          </v-row>
        </v-card-text>    
        </v-card>

      </div>

      <v-dialog
        v-model="dialogValidacion"
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-title class="mb-5">
            <span class="text-h5">Errores de validación</span>
          </v-card-title>
          <v-card-text>
           <errores-validacion :errors="validationErrors" v-if="validationErrors"></errores-validacion>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialogValidacion = false"
            >
              Cerrar
            </v-btn>            
          </v-card-actions>
        </v-card>
      </v-dialog>
     
  </div>
</template>

<script>
import {mapMutations,mapState,mapActions} from 'vuex'
import axios from 'axios'
import * as CONFIG from '@/config/config.js'
import * as checkPermiso from '@/config/checkPermisos.js'
import ErroresValidacion from '@/components/ErroresValidacion.vue'
import Cart from '@/components/Cart.vue'
import DetallePrecioCotizacionCart from '@/components/DetallePrecioCotizacionCart.vue'
export default {
  name:'productos',
  data(){
    return {
      items: [
                {
                    text: 'Inicio',
                    disabled: false,
                    exact: true,
                    to: {name: 'Home'}
                },
                {
                    text: 'Carrito de cotizaciones',
                    disabled: true,
                }
            ],      
      buscando: false,     
      cotizar:{
        producto_id:'',
        agregar_carrito:'',
        select_cliente_carrito:'',
        cantidad:1,
        cliente:{}
      },
      clientes:[],
      dialog:false,
      dialogValidacion:false,
      validationErrors:'',
      detalle:[]
    }
  },
   components: {
    ErroresValidacion,
    Cart,
    DetallePrecioCotizacionCart
  },
  methods:{
     ...mapMutations(['setAlert','SnackbarShow']),
     ...mapActions(['getDetalleCarrito']),
    verificarPermiso(permiso){
          return checkPermiso.tienePermiso(permiso);
    }, 
    getClientes() {

      return new Promise((resolve, reject) => {                    
            this.buscando = true;        
            axios.get(CONFIG.urlApi+'clientes/getClientes',{})
          .then(resp => {
              
              this.clientes = resp.data.data;
              this.buscando = false;
  
          })
          .catch(err => {                  
              
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
                  });
              this.buscando = false;
          });
                    
      });
    },
    getDataCliente(){
      return new Promise((resolve, reject) => {                    
            this.buscando = true;        
            axios.get(CONFIG.urlApi+'clientes/getCliente/'+this.cotizar.cliente.id,{})
          .then(resp => {
              let data_cliente = resp.data.data;
              this.cotizar.cliente.id = data_cliente.id;
              this.cotizar.cliente.nombre = data_cliente.nombre;
              this.cotizar.cliente.telefono = data_cliente.telefono;
              this.cotizar.cliente.email = data_cliente.email;
              this.cotizar.cliente.bonificacion = data_cliente.bonificacion;
              this.buscando = false;  
          })
          .catch(err => {                  
              
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
                  });
              this.buscando = false;
          });
                    
      });
    },
    generarCotizacion(){
      return new Promise((resolve, reject) => {
            
            let data = {          
              nuevo_cliente:this.cotizar.cliente,             
              bonificacion:this.cotizar.cliente.bonificacion,
              descripcion:this.cotizar.cliente.descripcion
            }
            
            this.buscando = true;
            axios.post(CONFIG.urlApi+'cotizaciones/agregarDesdeCarrito',data)
          .then(resp => {
              
              this.SnackbarShow({
                                    tipo: 'success',
                                    mensaje: resp.data.statusText
                                });
              this.$router.push({name:'Cotizaciones'} )
  
          })
          .catch(err => {

            if(err.validaciones){
              this.validationErrors = err.validaciones;
              this.dialogValidacion = true;
            }

              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
                  });
              this.buscando = false;
          });
                    
      });
    },
   
    getDataDetalleCarrito(){
      this.getDetalleCarrito(this.cotizar.cliente.bonificacion).then(response => {
        console.log("detalle",response.data.data)
          this.detalle = response.data.data;
      });
    }
  },
  created(){
   
    this.getClientes();
    this.getDataDetalleCarrito();
   
  },
  computed:{
    ...mapState(['itemsCart']),
      optionClientes(){
          return this.clientes.map(cliente => {
              return {value:cliente.id,text:cliente.nombre}
          });
      }
  },
   
  
}
</script>
