<template>
    <div>

        <template>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>Subtotal</td>
                  <td>{{ detalle.data_subtotal }}</td>
                </tr>
                <tr>
                  <td>Bonif % (%)</td>
                  <td>{{ detalle.bonificacion }}</td>
                </tr>
                <tr>
                  <td>Grabado</td>
                  <td>{{ detalle.grabado }}</td>
                </tr>
                <tr>
                  <td>IVA %21</td>
                  <td>{{ detalle.iva }}</td>
                </tr>
                 <tr>
                  <td>Total</td>
                  <td>{{ detalle.total }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>

    </div>

</template>
<script>
export default {
    name:'DetallePrecioCotizacionCart',
    props:{        
        detalle:[Object,Array]
    },
    data(){
        return{
           
        }
    },
    watch:{
        
    },
    methods:{
      
    },
    created(){
        console.log(this.detalle)
    },
}
</script>