<template>
  <div>
    <v-breadcrumbs :items="items" class="pt-0">
        <template v-slot:divider>
            <v-icon :style="{'font-size': '10px'}">fas fa-chevron-right</v-icon>
        </template>
    </v-breadcrumbs>

      <div>       
        
        <v-card
        class="mx-auto"
        outlined
        elevation="10"
        >

        <div v-if="buscando">
            <v-progress-linear
            indeterminate
            color="primary"
            ></v-progress-linear>
        </div>
        <v-card-title>
            Cotizar producto       
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-autocomplete
              label="Producto"  
              :items="optionProductos"                                
              v-model="cotizar.producto_id"
              @change="getPropiedadesProducto"             
              :rules="[v => !!v || 'Debe seleccionar un producto']"
              required
              ></v-autocomplete>
            </v-col>

            <v-col
              cols="12"
              md="4"
            >
              <v-text-field type="number"  label="Cantidad" v-model="cotizar.cantidad" @change="buscarCotizacion"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
              <template  v-for="(propiedad) in producto.producto_propiedades">
                <v-col cols="12" md="2" v-if="propiedad.descripcion_propiedad.tipo == 10">
                  <v-autocomplete                                                                                                       
                    :label="propiedad.descripcion_propiedad.nombre"
                    :items="formatItems(propiedad.propiedad_id)"
                    v-model="elementos_opciones[propiedad.propiedad_id]"
                   @change="buscarCotizacion"
                  ></v-autocomplete>
                </v-col>                                
                <template v-if="propiedad.descripcion_propiedad.tipo == 20">                                   
                      <v-col cols="12" md="2" v-for="(opcion,idx) in array_producto_opciones_propiedades[propiedad.propiedad_id]" :key="idx">
                        <v-text-field                                                                     
                        :label="propiedad.descripcion_propiedad.nombre +'('+opcion.nombre+')'"                  
                        v-model="elementos_rango[opcion.opcion_propiedad_id]"
                        @change="buscarCotizacion"
                        ></v-text-field>
                      </v-col>
                </template>                    
              </template>
              <v-col
                cols="12"
                md="2"
                class="pt-6"
              >
               <!--v-btn color="success" type="submit" @click="buscarCotizacion"><i class="fas fa-check mx-1"></i> Cotizar</v-btn-->
              </v-col>
          </v-row>
          <v-row>
             <v-col
                cols="12"
                md="12"
                class="pt-6 text-right"
                v-if="(piezas_cotizacion.piezas && piezas_cotizacion.piezas.length)"
              >
               
                  <v-btn                          
                  color="primary"
                  depressed
                  elevation="2" 
                  @click="dialog=true"                  
                  >
                    <v-icon
                    class="mr-1"> fas fa-dollar-sign</v-icon>
                        Cotizar                 
                  </v-btn>
                
              </v-col>

               <v-dialog
                    v-model="dialog"
                    persistent
                    max-width="600px"
                  >
                    <v-card>
                      <v-card-title class="mb-5">
                        <span class="text-h5">Generar cotización</span>
                      </v-card-title>
                      <v-card-text>
                        
                          <v-row>

                            <v-col
                            cols="12"
                            sm="6"
                            md="4"
                            >
                                <v-text-field
                                    label="Nombre"
                                    v-model="cotizar.cliente.nombre"
                                ></v-text-field>
                            </v-col>

                            <v-col
                            cols="12"
                            sm="6"
                            md="4"
                            >
                                <v-text-field
                                    label="Teléfono"
                                    v-model="cotizar.cliente.telefono"
                                ></v-text-field>
                            </v-col>

                            <v-col
                            cols="12"
                            sm="6"
                            md="4"
                            >
                                <v-text-field
                                    label="Email"
                                    v-model="cotizar.cliente.email"
                                ></v-text-field>
                            </v-col>
                            
                          </v-row>
                                           
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="dialog = false"
                        >
                          Cerrar
                        </v-btn>                        
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="generarCotizacion"
                        >
                          Generar cotización
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                </v-dialog>
             
          </v-row>

          <v-row>
            
            <v-col cols="12" md="12">
              <piezas-cotizacion
                      :ver_precio="true"
                      :piezas="piezas_cotizacion"
              />
            </v-col>
          </v-row>
        </v-card-text>
        </v-card>

      </div>

      <v-dialog
        v-model="dialogValidacion"
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-title class="mb-5">
            <span class="text-h5">Errores de validación</span>
          </v-card-title>
          <v-card-text>
           <errores-validacion :errors="validationErrors" v-if="validationErrors"></errores-validacion>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialogValidacion = false"
            >
              Cerrar
            </v-btn>            
          </v-card-actions>
        </v-card>
      </v-dialog>
     
  </div>
</template>

<script>
import {mapMutations,mapActions} from 'vuex'
import axios from 'axios'
import * as CONFIG from '@/config/config.js'
import * as checkPermiso from '@/config/checkPermisos.js'
import ErroresValidacion from '@/components/ErroresValidacion.vue'
import PiezasCotizacion from '@/components/PiezasCotizacion.vue'
export default {
  name:'cotizarWeb',
  data(){
    return {      
      items: [
                {
                    text: 'Inicio',
                    disabled: false,
                    exact: true,
                    to: {name: 'Home'}
                },
                {
                    text: 'Cotizar Producto',
                    disabled: true,
                }
            ],      
      buscando: false,        
      productos:[],
      array_producto_opciones_propiedades:[],
      producto:[],
      cotizar:{
        producto_id:'',
        agregar_carrito:'',
        select_cliente_carrito:'',
        cantidad:1,
        cliente:{}
      },
      elementos_rango:[],
      elementos_opciones:[],
      array_config_opciones_rango:[],
      array_config_opciones:[],
      piezas_cotizacion:[],
      
      dialog:false,
      dialogValidacion:false,
      validationErrors:''
    }
  },
   components: {
    ErroresValidacion,
    PiezasCotizacion
  },
  methods:{
     ...mapMutations(['setAlert','SnackbarShow']),
      ...mapActions(['getItemsCart']),
    verificarPermiso(permiso){
          return checkPermiso.tienePermiso(permiso);
    }, 
    getProductos(){
      
      return new Promise((resolve, reject) => {                    
            this.buscando = true;  
            axios.get(CONFIG.urlApi+'cotizacionWeb/getProductos',{})
          .then(resp => {
            
              this.buscando = false;
              this.productos = resp.data.data;

          })
          .catch(err => {                  
            
              this.buscando = false;
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
                  });

          });
                    
      });
    },
    getPropiedadesProducto(){
      
      return new Promise((resolve, reject) => {                    
            this.buscando = true;  
            axios.get(CONFIG.urlApi+'cotizacionWeb/productoPropiedadesCotizar/'+this.cotizar.producto_id,{})
          .then(resp => {

             this.buscando = false;
             this.piezas_cotizacion = [];
             this.array_producto_opciones_propiedades = resp.data.data.array_producto_opciones_propiedades;
             this.producto = resp.data.data.producto;
          })
          .catch(err => {                  
              
              this.buscando = false;
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
                  });

          });
                    
      });
    },
    formatItems(propiedad_id){
        if(this.array_producto_opciones_propiedades[propiedad_id]){
          return this.array_producto_opciones_propiedades[propiedad_id].map(data => {
              return {value:data.opcion_propiedad_id,text:data.nombre}
          });
        }
         return []; 
    },
    formatRango(){     
      this.array_config_opciones_rango = [];
      this.array_config_opciones = [];

      this.elementos_rango.forEach((data, key) => {
        this.array_config_opciones_rango.push({opcion_propiedad_id: key,valor : data});
      });

      this.elementos_opciones.forEach((data, key) => {
        this.array_config_opciones.push(data);
      });

    },
    buscarCotizacion(){
      this.formatRango();
      let data = {
        producto_id: this.cotizar.producto_id,
        opciones:    this.array_config_opciones,
        rangos:      this.array_config_opciones_rango,
        cantidad:    this.cotizar.cantidad
      }

      return new Promise((resolve, reject) => {                    
            this.buscando = true;  
            axios.post(CONFIG.urlApi+'cotizacionWeb/productoPiezasCotizar',data)
          .then(resp => {
            
            this.buscando = false;
            if(resp.data.data){
              this.piezas_cotizacion = resp.data.data;
            }

          })
          .catch(err => {
              this.buscando = false;
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
                  });

          });
                    
      });

    },
    generarCotizacion(){
      return new Promise((resolve, reject) => {
            
            let data = {
              producto_id: this.cotizar.producto_id,
              opciones:    this.array_config_opciones,
              rangos:      this.array_config_opciones_rango,
              cantidad:    this.cotizar.cantidad,
              nuevo_cliente:this.cotizar.cliente,
              descripcion:this.cotizar.cliente.descripcion
            }
            
            this.buscando = true;
            axios.post(CONFIG.urlApi+'cotizacionWeb/agregar',data)
          .then(resp => {
            this.buscando = false;            
              this.SnackbarShow({
                                    tipo: 'success',
                                    mensaje: resp.data.statusText
                                });
  
          })
          .catch(err => {

              this.buscando = false;
              if(err.validacion){
                this.validationErrors = err.validacion;
                this.dialogValidacion = true;
              }

              this.SnackbarShow({
                  tipo: 'error',
                  mensaje: err.statusText
              });
              
          });
                    
      });
    },
    
  },
  created(){
    this.getProductos();
    //verifico si existe el parametro para poder traer su información
    if(this.$route.params.producto_id){
        this.cotizar.producto_id = parseInt(this.$route.params.producto_id);
        this.getPropiedadesProducto();
    }
  },
  computed:{
      optionProductos(){
          return this.productos.map(producto => {
              return {value:producto.id,text:producto.nombre}
          });
      },      
  },
  
}
</script>
