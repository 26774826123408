<template>
  <div>
    <v-breadcrumbs :items="items" class="pt-0">
        <template v-slot:divider>
            <v-icon :style="{'font-size': '10px'}">fas fa-chevron-right</v-icon>
        </template>
    </v-breadcrumbs>

      <div>
        <div v-if="buscando">
            <v-progress-linear
            indeterminate
            color="primary"
            ></v-progress-linear>
        </div>
        
        <v-card
        class="mx-auto"
        outlined
        elevation="10"
       max-width="1000"
        >
          <v-data-table
            :headers="headers"
            :items="piezas"
            :search="search"
          >
            <template v-slot:top>
              <v-toolbar
                flat
              >                 
                  <v-toolbar-title>Piezas</v-toolbar-title>
                  <v-divider
                    class="mx-4"
                    inset
                    vertical
                  ></v-divider>
                  <v-spacer>
                  </v-spacer>
                    <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    hide-details
                    class="col-md-4 mr-6"
                  ></v-text-field>

                  <v-btn
                      color="success"
                      dark
                      class="mb-2"                      
                      elevation="12"
                      v-if="verificarPermiso(12)"
                      @click="openModal(null,'agregar')"        
                    >
                    <v-icon>fas fa-plus </v-icon>
                  </v-btn>

                  <v-dialog
                    v-model="dialog"
                    persistent
                    max-width="600px"
                  >                  
                    <v-card>
                      <v-card-title>
                        <span class="text-h5" v-if="tipo == 'editar'">Editar Pieza</span>
                        <span class="text-h5" v-if="tipo == 'agregar'">Agregar Pieza</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>                            
                            <v-col md="6" xs="12">
                                  <v-text-field
                                  v-model="pieza.codigo"
                                  label="Código"                   
                                  ></v-text-field>
                            </v-col>
                            <v-col md="6" xs="12">
                                  <v-text-field
                                  v-model="pieza.nombre"
                                  label="Nombre"                   
                                  ></v-text-field>
                            </v-col>                            
                             <v-col md="6" xs="12">
                                  <v-text-field
                                  v-model="pieza.precio"
                                  label="Precio"                   
                                  ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>                       
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="dialog = false"
                        >
                          Cerrar
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          v-if="tipo == 'editar'"
                          @click="editar"
                        >
                          Editar
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          v-if="tipo == 'agregar'"
                          @click="agregar"
                        >
                          Agregar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <v-dialog v-model="dialogDelete" max-width="550">
                    <v-card>
                      <v-toolbar
                        color="primary"
                        dark
                      >Eliminar
                      </v-toolbar>
                      <v-card-text class="text-h6 pa-10">Está seguro que desea eliminar el pieza?</v-card-text>
                      <v-card-actions  class="justify-end">
                        <v-spacer></v-spacer>
                        <v-btn color="darken-1" text @click="closeDelete">Cancelar</v-btn>
                        <v-btn color="darken-1" class="primary" primary text @click="deleteItemConfirm">Confirmar</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">              
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                   <v-icon
                    v-on="on"
                    small
                    class="mr-2"
                    color="amber darken-2"
                    v-if="verificarPermiso(13)"
                    @click="openModal(item,'editar')"                    
                  >
                  fas fa-edit
                </v-icon>
                </template>
                <span>Editar</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                   <v-icon
                    v-on="on"
                    small
                    color="red darken-2"
                    v-if="verificarPermiso(14)"
                    @click="deleteItem(item.id)"                    
                  >
                    fas fa-trash
                  </v-icon>
                </template>
                <span>Eliminar</span>
              </v-tooltip>

             </template>

          </v-data-table>          
        </v-card>

      </div>
     
  </div>
</template>

<script>
import {mapMutations} from 'vuex'
import axios from 'axios'
import * as CONFIG from '@/config/config.js'
import * as checkPermiso from '@/config/checkPermisos.js'
export default {
  name:'piezas',
  data(){
    return {      
      items: [
                {
                    text: 'Inicio',
                    disabled: false,
                    exact: true,
                    to: {name: 'Home'}
                },
                {
                    text: 'Piezas',
                    disabled: true,
                }
            ],      
      buscando: false,
      search:'',
      headers:[
        { text: 'Código', value: 'codigo' },
        { text: 'Nombre', value: 'nombre'},
        { text: 'Precio', value: 'precio' },
        { text: 'Acciones', value: 'actions', sortable: false,width:'10%' }
      ],
      piezas:[],
      pieza:{},
      dialog:false,
      tipo:'',
      dialogDelete:false,
    }
  },
  methods:{
     ...mapMutations(['setAlert','SnackbarShow']),
    verificarPermiso(permiso){
          return checkPermiso.tienePermiso(permiso);
    }, 
    getPiezas() {

      return new Promise((resolve, reject) => {                    
            this.buscando = true;        
            axios.get(CONFIG.urlApi+'piezas/getPiezas',{})
          .then(resp => {
              
              this.piezas = resp.data.data;
              this.buscando = false;
          })
          .catch(err => {                  
              
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
                  });
              this.buscando = false;
          });
                    
      });
    },
    openModal(data,tipo) {

        this.tipo = tipo;
        this.pieza = {};
        
        if(tipo == 'editar'){
          this.pieza = data;
        }
        
        this.dialog = true;
    },
    agregar(){

      return new Promise((resolve, reject) => {
            this.buscando = true;
            let data_pieza = this.pieza;
            axios({url: CONFIG.urlApi+'piezas/agregar',data:data_pieza,method: 'POST' })
            .then(resp => {
              
              this.pieza = {};
              this.dialog = false;
              
              this.SnackbarShow({
                                    tipo: 'success',
                                    mensaje: resp.data.statusText
                                });
              
              this.getPiezas();
              this.buscando = false;
              
          })
          .catch(err => {                  
              console.log(err);
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
                  });
              this.buscando = false;

          });
                    
      });

    },
    editar(){

      return new Promise((resolve, reject) => {
            this.buscando = true;
            axios({url: CONFIG.urlApi+'piezas/editar/'+this.pieza.id,data:this.pieza,method: 'PUT' })
            .then(resp => {

              this.pieza = {};
              this.dialog = false;
              this.SnackbarShow({
                                    tipo: 'success',
                                    mensaje: resp.data.statusText
                                });
              this.getPiezas();
              this.buscando = false;
              
          })
          .catch(err => {                  
              
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
                  });
              this.buscando = false;

          });
                    
      });

    },
    deleteItem (item_id) {
        this.pieza.id = item_id;
        this.dialogDelete = true
    },
    deleteItemConfirm () {
        
      return new Promise((resolve, reject) => {                    
            this.buscando = true;        
            axios.delete(CONFIG.urlApi+'piezas/eliminar/'+this.pieza.id, {})
          .then(resp => {                
             
              this.pieza.id = {};

              this.SnackbarShow({
                                    tipo: 'success',
                                    mensaje: resp.data.statusText
                                });

              this.getPiezas();
              this.buscando = false;
              
          })
          .catch(err => {                  
              
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
                  });
              this.buscando = false;

          });
            this.closeDelete();
                    
      });
    },
    closeDelete () {
      this.dialogDelete = false
    },
  },
  watch: {
      dialogDelete (val) {
        val || this.closeDelete()
      },
  },
  mounted(){
    this.getPiezas();
  }
  
}
</script>
