<template>
    <div>
        <v-breadcrumbs :items="items" class="pt-0">
            <template v-slot:divider>
                <v-icon :style="{'font-size': '10px'}">fas fa-chevron-right</v-icon>
            </template>
        </v-breadcrumbs>

        <v-card
        class="mx-auto"
        outlined
        elevation="10"
        >

           <div v-if="cargando_seccion">
                <v-progress-linear
                indeterminate
                color="primary"
                ></v-progress-linear>
            </div> 

            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="headline mb-3">Editar Usuario</v-list-item-title>
                    <v-list-item-subtitle class="mb-3"></v-list-item-subtitle>
                    <div v-if="usuario.id != ''">
                        

                        <form @submit.prevent="guardar()">
                            <v-row>                            
                                <v-col cols="12" md="12">

                                   <h2>{{usuario.nombre +' '+usuario.apellido}} </h2> 
                                      <h4>({{usuario.cuenta}})</h4>                  
                                </v-col>

                                <v-divider></v-divider>

                                <v-col cols="12" md="12">
                                    <v-row>
                                         <v-col cols="6" md="6">
                                                                                                       
                                        </v-col>
                                    </v-row>
                                   
                                    
                                    <v-divider></v-divider>
                                    
                                    <v-row>
                                        <v-col cols="12" md="4">
                                            <v-select label="Rol" outlined :items="optionRoles"
                                                v-model="usuario.rol_id" dense
                                                :disabled="cargando_seccion"
                                                @change="getPermisos_rolId()"
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-checkbox                                   
                                                v-model="usuario.permiso_especial"
                                                :input-value="usuario.permiso_especial"
                                                label="Permiso especial"
                                                color="indigo darken-3"
                                                hide-details
                                                @change="checkPermisoEspecial()"
                                            ></v-checkbox>
                                        </v-col>
                                    </v-row>
                                    

                                    <v-col cols="12" md="12">
                                        <v-row>
                                            <v-col cols="3" md="3"
                                            v-for="(grupo,index) of grupo_permisos"
                                            :key="index"
                                            >
                                                <v-card
                                                    class="mx-auto"
                                                    max-width="344"                                           
                                                >
                                                    <v-card-actions>
                                                    <v-btn
                                                        text
                                                        color="indigo darken-3"
                                                    > 
                                                        {{grupo.descripcion}}                                       
                                                    </v-btn>
                                                    </v-card-actions>

                                                    <v-expand-transition>
                                                    <div>
                                                        <v-divider></v-divider>
                                                        <v-card-text>

                                                            <v-checkbox
                                                            v-for="(permiso,idx) of grupo.permisos"
                                                            v-model="usuario.permisos"
                                                            :input-value="(usuario.permisos.includes(permiso.id))"
                                                            :label="permiso.descripcion"
                                                            color="indigo darken-3"
                                                            :value="permiso.id"
                                                            :key="idx"
                                                            :readonly="!usuario.permiso_especial"
                                                            hide-details
                                                            ></v-checkbox>
                                                                                                            
                                                        </v-card-text>
                                                    </div>
                                                    </v-expand-transition>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-col>

                                    <v-col cols="12">
                                        <v-btn color="success" type="submit" :loading="cargando_seccion"><i class="fas fa-check mx-1"></i> Guardar</v-btn>
                                        <v-btn color="warning" class="mx-2" :disabled="cargando_seccion" :to="{name: 'Usuarios'}"><i class="fas fa-long-arrow-alt-left mx-1"></i> Salir</v-btn>
                                    </v-col>
                                </v-col>
                                
                            </v-row>
                        </form>
                    </div>
                    <div v-else>
                        Usuario no encontrado
                    </div>
                </v-list-item-content>
            </v-list-item>
        </v-card>

    </div>
</template>

<script>
import {mapActions,mapMutations} from 'vuex'
import axios from 'axios'
import * as CONFIG from '@/config/config.js'
    export default{
        name: 'AgregarRestriccion',
        data: () => ({
            items: [
                {
                    text: 'Inicio',
                    disabled: false,
                    exact: true,
                    to: {name: 'Home'}
                },
                {
                    text: 'Usuarios',
                    disabled: false,
                    exact: true,
                    to: {name: 'Usuarios'}
                },
                {
                    text: 'Editar Usuario',
                    disabled: true,
                }
            ],
            cargando_seccion: false,            
            usuario:{
                 id:null,                 
                 rol_id:null,
                 nombre:null,
                 apellido:null,
                 cuenta:null,
                 permiso_especial:false,
                 permisos:[],
                 cuenta_permisos:[]                        
            },
            roles:[],
            grupo_permisos:[],
            cuentas: [],        

        }),
        methods:{
            ...mapActions(['getCuentas','getServicios']),
            ...mapMutations(['setAlert','SnackbarShow']),
            guardar(){                    
                return new Promise((resolve, reject) => {
                        
                        axios({url: CONFIG.urlApi+'usuarios/editar',data:{usuario:this.usuario},method: 'POST' })
                        .then(resp => {
                            const token = resp.data.jwt
                            localStorage.setItem('token', token)
                            axios.defaults.headers.common['Authorization'] = token          
                            if(resp.data.status == 1){                            
                                this.SnackbarShow({
                                    tipo: 'success',
                                    mensaje: resp.data.statusText
                                });
                                this.$router.push({name:'Usuarios'});                            
                            }
                            if(resp.data.status == 0 || resp.data.status == 4 || resp.data.status == 5){                            
                                this.SnackbarShow({
                                    tipo: 'warning',
                                    mensaje: resp.data.statusText
                                });                                
                            }
                           
                        })
                        .catch(err => {                   
                        this.SnackbarShow({
                                    tipo: 'error',
                                    mensaje: err.statusText
                                });
                            reject(err)
                        })
                    })
    
            },
            getUsuarioEdit(usuario_id){                    
                return new Promise((resolve, reject) => {
                        
                        axios({url: CONFIG.urlApi+'usuarios/get_usuario',data:{id:usuario_id},method: 'POST' })
                        .then(resp => {
                            this.cargando_seccion = false;
                            const token = resp.data.jwt
                            localStorage.setItem('token', token)
                            axios.defaults.headers.common['Authorization'] = token

                            this.usuario.id = resp.data.result.info.documento;
                            this.usuario.nombre = resp.data.result.info.nombre;
                            this.usuario.apellido = resp.data.result.info.apellido;
                            this.usuario.cuenta = resp.data.result.info.cuenta_descripcion;
                            
                           
                            this.usuario.permiso_especial = resp.data.result.usuario.permiso_especial;
                            this.usuario.rol_id = resp.data.result.usuario.rol_id;
                            this.usuario.permisos = resp.data.result.usuario.permisos;
                            this.usuario.cuenta_permisos = resp.data.result.usuario.cuentas_usuario;

                            console.log(this.usuario.cuenta_permisos)
                                                                        
                        })
                        .catch(err => {
                            console.log(err);
                        })
                    })
    
            },
            getGruposConPermisos(){                    
                return new Promise((resolve, reject) => {
                        
                        axios({url: CONFIG.urlApi+'roles/get_grupos_con_permisos',data:{},method: 'POST' })
                        .then(resp => {
                            this.cargando_seccion = false;
                            const token = resp.data.jwt
                            localStorage.setItem('token', token)
                            axios.defaults.headers.common['Authorization'] = token                                                
                            this.grupo_permisos = resp.data.result.grupo_permisos;
                        })
                        .catch(err => {                   
                            console.log(err);
                        })
                    })
    
            },
            getRoles() {
        
                return new Promise((resolve, reject) => {                    
                        let dataTable = false;        
                        axios.post(CONFIG.urlApi+'roles/get_roles', {dataTable})
                    .then(resp => {
                        const token = resp.data.jwt
                        localStorage.setItem('token', token)
                        axios.defaults.headers.common['Authorization'] = token;
                        this.roles = resp.data.result;                          
            
                    })
                    .catch(err => {                  
                        
                        this.SnackbarShow({
                                tipo: 'error',
                                mensaje: err.statusText
                            });
                        reject(err)

                    });
                                
                });
            },
            getPermisos_rolId(){                    
                return new Promise((resolve, reject) => {
                        
                        axios({url: CONFIG.urlApi+'roles/get_permisos_rolId',data:{id:this.usuario.rol_id},method: 'POST' })
                        .then(resp => {
                            this.cargando_seccion = false;
                            const token = resp.data.jwt
                            localStorage.setItem('token', token)
                            axios.defaults.headers.common['Authorization'] = token                                                
                            this.usuario.permisos = resp.data.result.permisos;
                        })
                        .catch(err => {                   
                            console.log(err);
                        })
                    })
    
            },
            checkPermisoEspecial(){
                if(!this.usuario.permiso_especial){
                    this.usuario.permisos = [];
                    this.getPermisos_rolId();
                }
            },
 
        },
        computed:{
            optionRoles(){
                return this.roles.map(rol => {
                    return {value:rol.id,text:rol.descripcion}
                });
            },
                    
        },
        created(){           
            this.getUsuarioEdit(this.$route.params.usuario_id);
            this.getRoles();
            this.getGruposConPermisos();
           
        },
    }
</script>