<template>
  <div>
    <v-breadcrumbs :items="items" class="pt-0">
        <template v-slot:divider>
            <v-icon :style="{'font-size': '10px'}">fas fa-chevron-right</v-icon>
        </template>
    </v-breadcrumbs>

      <div>        
        
        <v-card
        class="mx-auto"
        outlined
        elevation="10"
        >
        <div v-if="buscando">
            <v-progress-linear
            indeterminate
            color="primary"
            ></v-progress-linear>
        </div>

          <v-data-table
            :headers="headers"
            :items="clientes"
            :search="search"
          >
            <template v-slot:top>
              <v-toolbar
                flat
              >                 
                  <v-toolbar-title>Clientes</v-toolbar-title>
                  <v-divider
                    class="mx-4"
                    inset
                    vertical
                  ></v-divider>
                  <v-spacer>
                  </v-spacer>
                    <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    hide-details
                    class="col-md-4 mr-6"
                  ></v-text-field>

                  <v-btn
                      color="success"
                      dark
                      class="mb-2"                      
                      elevation="12"
                      v-if="verificarPermiso(16)"
                      @click="openModal(null,'agregar')"        
                    >
                    <v-icon>fas fa-plus </v-icon>
                  </v-btn>

                  <v-dialog
                    v-model="dialog"
                    persistent
                    max-width="600px"
                  >                  
                    <v-card>
                      <v-card-title>
                        <span class="text-h5" v-if="tipo == 'editar'">Editar Cliente</span>
                        <span class="text-h5" v-if="tipo == 'agregar'">Agregar Cliente</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>                            
                            <v-col md="6" xs="12">
                                  <v-text-field
                                  v-model="cliente.nombre"
                                  label="Nombre"                   
                                  ></v-text-field>
                            </v-col>  
                            <v-col md="6" xs="12">
                                  <v-text-field
                                  v-model="cliente.telefono"
                                  label="Teléfono"                   
                                  ></v-text-field>
                            </v-col>
                             <v-col md="6" xs="12">
                                  <v-text-field
                                  v-model="cliente.email"
                                  label="E-mail"                   
                                  ></v-text-field>
                            </v-col>
                             <v-col md="6" xs="12">
                                  <v-text-field
                                  v-model="cliente.nombre_contacto"
                                  label="Nombre contacto"                   
                                  ></v-text-field>
                            </v-col>
                             <v-col md="6" xs="12">
                                  <v-text-field
                                  v-model="cliente.cargo_contacto"
                                  label="Cargo contacto"                   
                                  ></v-text-field>
                            </v-col>     
                             <v-col md="6" xs="12">
                                  <v-text-field
                                  v-model="cliente.codigo"
                                  label="Código"                   
                                  ></v-text-field>
                            </v-col> 
                            <v-col md="6" xs="12">
                                  <v-text-field
                                  v-model="cliente.razon_social"
                                  label="Razón social"                   
                                  ></v-text-field>
                            </v-col>
                            <v-col md="6" xs="12">
                                  <v-text-field
                                  v-model="cliente.domicilio"
                                  label="Domicilio"                   
                                  ></v-text-field>
                            </v-col> 

                            <v-col md="6" xs="12">
                                  <v-text-field
                                  v-model="cliente.codigo_postal"
                                  label="Código postal"                   
                                  ></v-text-field>
                            </v-col> 
                            <v-col md="6" xs="12">
                                  <v-text-field
                                  v-model="cliente.localidad"
                                  label="Localidad"                   
                                  ></v-text-field>
                            </v-col>
                            <v-col md="6" xs="12">
                                  <v-text-field
                                  v-model="cliente.provincia"
                                  label="Provincia"                   
                                  ></v-text-field>
                            </v-col>
                            <v-col md="6" xs="12">
                                  <v-text-field
                                  v-model="cliente.tipo_iva"
                                  label="Tipo IVA"                   
                                  ></v-text-field>
                            </v-col>
                            <v-col md="6" xs="12">
                                  <v-text-field
                                  v-model="cliente.identificacion"
                                  label="Identificación"                   
                                  ></v-text-field>
                            </v-col>
                            <v-col md="6" xs="12">
                                  <v-text-field
                                  v-model="cliente.nombre_comercial"
                                  label="Nombre comercial"                   
                                  ></v-text-field>
                            </v-col>
                            <v-col md="6" xs="12">
                                  <v-text-field
                                  v-model="cliente.direccion_comercial"
                                  label="Dirección"                   
                                  ></v-text-field>
                            </v-col>

                          </v-row>
                        </v-container>                       
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="dialog = false"
                        >
                          Cerrar
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          v-if="tipo == 'editar'"
                          @click="editar"
                        >
                          Editar
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          v-if="tipo == 'agregar'"
                          @click="agregar"
                        >
                          Agregar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <v-dialog v-model="dialogDelete" max-width="550">
                    <v-card>
                      <v-toolbar
                        color="primary"
                        dark
                      >Eliminar
                      </v-toolbar>
                      <v-card-text class="text-h6 pa-10">Está seguro que desea eliminar el cliente?</v-card-text>
                      <v-card-actions  class="justify-end">
                        <v-spacer></v-spacer>
                        <v-btn color="darken-1" text @click="closeDelete">Cancelar</v-btn>
                        <v-btn color="darken-1" class="primary" primary text @click="deleteItemConfirm">Confirmar</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">              
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                   <v-icon
                    v-on="on"
                    small
                    class="mr-2"
                    color="amber darken-2"
                    v-if="verificarPermiso(17)"
                    @click="openModal(item,'editar')"                    
                  >
                  fas fa-edit
                </v-icon>
                </template>
                <span>Editar</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                   <v-icon
                    v-on="on"
                    small
                    color="red darken-2"
                    v-if="verificarPermiso(18)"
                    @click="deleteItem(item.id)"                    
                  >
                    fas fa-trash
                  </v-icon>
                </template>
                <span>Eliminar</span>
              </v-tooltip>

             </template>

          </v-data-table>          
        </v-card>

      </div>
     
  </div>
</template>

<script>
import {mapMutations} from 'vuex'
import axios from 'axios'
import * as CONFIG from '@/config/config.js'
import * as checkPermiso from '@/config/checkPermisos.js'
export default {
  name:'clientes',
  data(){
    return {      
      items: [
                {
                    text: 'Inicio',
                    disabled: false,
                    exact: true,
                    to: {name: 'Home'}
                },
                {
                    text: 'Clientes',
                    disabled: true,
                }
            ],      
      buscando: false,
      search:'',
      headers:[
        { text: 'Nombre', value: 'nombre' },
        { text: 'Teléfono', value: 'telefono'},
        { text: 'E-mail', value: 'email' },
        { text: 'Acciones', value: 'actions', sortable: false,width:'10%' }
      ],
      clientes:[],
      cliente:{},
      dialog:false,
      tipo:'',
      dialogDelete:false,
    }
  },
  methods:{
     ...mapMutations(['setAlert','SnackbarShow']),
    verificarPermiso(permiso){
          return checkPermiso.tienePermiso(permiso);
    }, 
    getClientes() {

      return new Promise((resolve, reject) => {                    
            this.buscando = true;        
            axios.get(CONFIG.urlApi+'clientes/getClientes',{})
          .then(resp => {
              
              this.clientes = resp.data.data;
              this.buscando = false;
  
          })
          .catch(err => {                  
              
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
                  });
              this.buscando = false;
          });
                    
      });
    },
    openModal(data,tipo) {

        this.tipo = tipo;
        this.cliente = {};
        
        if(tipo == 'editar'){
          this.cliente = data;
        }
        
        this.dialog = true;
    },
    agregar(){

      return new Promise((resolve, reject) => {
            this.buscando = true;
            let data_cliente = this.cliente;
            axios({url: CONFIG.urlApi+'clientes/agregar',data:data_cliente,method: 'POST' })
            .then(resp => {
              
              this.cliente = {};
              this.dialog = false;
              
              this.SnackbarShow({
                                    tipo: 'success',
                                    mensaje: resp.data.statusText
                                });
              
              this.getClientes();
              this.buscando = false;
              
          })
          .catch(err => {                  
              console.log(err);
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
                  });
              this.buscando = false;

          });
                    
      });

    },
    editar(){

      return new Promise((resolve, reject) => {
            
            this.buscando = true;
            axios({url: CONFIG.urlApi+'clientes/editar/'+this.cliente.id,data:this.cliente,method: 'PUT' })
            .then(resp => {

              this.cliente = {};
              this.dialog = false;
              this.SnackbarShow({
                                    tipo: 'success',
                                    mensaje: resp.data.statusText
                                });
              this.getClientes();
              this.buscando = false;
              
          })
          .catch(err => {                  
              
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
                  });
              this.buscando = false;

          });
                    
      });

    },
    deleteItem (item_id) {
        this.cliente.id = item_id;
        this.dialogDelete = true
    },
    deleteItemConfirm () {
        
      return new Promise((resolve, reject) => {                    
           this.buscando = true;         
            axios.delete(CONFIG.urlApi+'clientes/eliminar/'+this.cliente.id, {})
          .then(resp => {                
             
              this.cliente.id = {};

              this.SnackbarShow({
                                    tipo: 'success',
                                    mensaje: resp.data.statusText
                                });

              this.getClientes();
              this.buscando = false;
              
          })
          .catch(err => {                  
              
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
                  });
              this.buscando = false;

          });
            this.closeDelete();
                    
      });
    },
    closeDelete () {
      this.dialogDelete = false
    },
  },
  watch: {
      dialogDelete (val) {
        val || this.closeDelete()
      },
  },
  mounted(){
    this.getClientes();
  }
  
}
</script>
