<template>
  <div>
    <v-breadcrumbs :items="items" class="pt-0">
        <template v-slot:divider>
            <v-icon :style="{'font-size': '10px'}">fas fa-chevron-right</v-icon>
        </template>
    </v-breadcrumbs>

      <div>        
        
        <v-card
        class="mx-auto"
        outlined
        elevation="10"
       max-width="1000"
        >
        <div v-if="buscando">
            <v-progress-linear
            indeterminate
            color="primary"
            ></v-progress-linear>
        </div>
          <v-data-table
            :headers="headers"
            :items="propiedades"
            :search="search"
          >
            <template v-slot:top>
              <v-toolbar
                flat
              >                 
                  <v-toolbar-title>Propiedades</v-toolbar-title>
                  <v-divider
                    class="mx-4"
                    inset
                    vertical
                  ></v-divider>
                  <v-spacer>
                  </v-spacer>
                    <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    hide-details
                    class="col-md-4 mr-6"
                  ></v-text-field>

                  <v-btn
                      color="success"
                      dark
                      class="mb-2"                      
                      elevation="12"
                      v-if="verificarPermiso(8)"
                      @click="openModal(null,'agregar')"        
                    >
                    <v-icon>fas fa-plus </v-icon>
                  </v-btn>

                  <v-dialog
                    v-model="dialog"
                    persistent
                    max-width="70%"
                  >                  
                    <v-card>
                      <v-card-title>
                        <span class="text-h5" v-if="tipo == 'editar'">Editar Propiedad</span>
                        <span class="text-h5" v-if="tipo == 'agregar'">Agregar Propiedad</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>                           
                            <v-col md="6" xs="12">
                                  <v-text-field
                                  v-model="propiedad.nombre"
                                  label="Nombre"                   
                                  ></v-text-field>
                            </v-col>                            
                             <v-col md="6" xs="12">
                                  <v-select
                                  :items="itemOpciones"
                                  item-text="nombre"
                                  item-value="tipo"
                                  label="Tipo"
                                  v-model="propiedad.tipo"
                                ></v-select>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col md="12" xs="12">
                              <v-card>
                                  <v-card-title class="text-h6">
                                      Opciones
                                  </v-card-title>                                                            
                                  <v-card-text>
                                    <v-col md="12" xs="12">
                                      <v-row>
                                        <v-col md="4" xs="12" v-for="(opcion,idx) of propiedad.opciones" :key="idx">
                                          {{ opcion.nombre }}
                                          <v-btn icon color="error" @click="eliminarOpcion(opcion.id,idx)">
                                            <v-icon>fas fa-trash</v-icon>
                                          </v-btn>
                                      </v-col>
                                      </v-row>
                                     </v-col>
                                  </v-card-text>
                                  <v-card-actions>
                                      <v-col cols="12" md="12">
                                      <v-row>
                                      <template v-for="(nuevo,ind) in nuevaOpcion">                                        
                                          <v-col cols="12" md="10" :key="ind">
                                              <v-text-field label="Nombre opción" outlined dense
                                              :rules="[v => !!v || 'Debe ingresar el nombre de la opción']"
                                              v-model="nuevo.nombre"
                                              required></v-text-field>
                                          </v-col>
                                          <v-col cols="12" md="2">
                                              <v-btn v-if="nuevo.accion == 1" @click="agregarOpcionDinamica" color="success" type="submit"><i class="fas fa-plus"></i></v-btn>
                                              <v-btn v-if="nuevo.accion == 2" @click="eliminarOpcionDinamica(ind)" color="error" type="submit"><i class="fas fa-trash"></i></v-btn>
                                          </v-col>
                                      </template>
                                      </v-row>
                                      </v-col>
                                  </v-card-actions>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-container>                       
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="dialog = false"
                        >
                          Cerrar
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          v-if="tipo == 'editar'"
                          @click="editar"
                        >
                          Editar
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          v-if="tipo == 'agregar'"
                          @click="agregar"
                        >
                          Agregar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <v-dialog v-model="dialogDelete" max-width="550">
                    <v-card>
                      <v-toolbar
                        color="primary"
                        dark
                      >Eliminar
                      </v-toolbar>
                      <v-card-text class="text-h6 pa-10">Está seguro que desea eliminar el propiedad?</v-card-text>
                      <v-card-actions  class="justify-end">
                        <v-spacer></v-spacer>
                        <v-btn color="darken-1" text @click="closeDelete">Cancelar</v-btn>
                        <v-btn color="darken-1" class="primary" primary text @click="deleteItemConfirm">Confirmar</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.tipo="props">
              <template>
                    
                    {{props.item.tipo == 10 ? 'Opciones' : 'Rango'}}
                     
                    
              </template>                
            </template>

            <template v-slot:item.actions="{ item }">              
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                   <v-icon
                    v-on="on"
                    small
                    class="mr-2"
                    color="amber darken-2"
                    v-if="verificarPermiso(9)"
                    @click="openModal(item,'editar')"                    
                  >
                  fas fa-edit
                </v-icon>
                </template>
                <span>Editar</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                   <v-icon
                    v-on="on"
                    small
                    color="red darken-2"
                    v-if="verificarPermiso(10)"
                    @click="deleteItem(item.id)"                    
                  >
                    fas fa-trash
                  </v-icon>
                </template>
                <span>Eliminar</span>
              </v-tooltip>

            </template>

          </v-data-table>
        </v-card>

      </div>
     
  </div>
</template>

<script>
import {mapMutations} from 'vuex'
import axios from 'axios'
import * as CONFIG from '@/config/config.js'
import * as checkPermiso from '@/config/checkPermisos.js'
export default {
  name:'propiedades',
  data(){
    return {
      items: [
                {
                    text: 'Inicio',
                    disabled: false,
                    exact: true,
                    to: {name: 'Home'}
                },
                {
                    text: 'Propiedades',
                    disabled: true,
                }
            ],      
      buscando: false,
      search:'',
      headers:[        
        { text: 'Nombre', value: 'nombre'},
        { text: 'Tipo', value: 'tipo' },
        { text: 'Acciones', value: 'actions', sortable: false,width:'10%' }
      ],
      propiedades:[],
      propiedad:{},
      dialog:false,
      tipo:'',
      dialogDelete:false,
      itemOpciones:[
        {tipo: 10,nombre:'Opciones'},
        {tipo: 20,nombre:'Rango'}
      ],
      nuevaOpcion:[
        {
          nombre:'',
          accion:1
        }
      ]
    }
  },
  methods:{
     ...mapMutations(['setAlert','SnackbarShow']),
    verificarPermiso(permiso){
          return checkPermiso.tienePermiso(permiso);
    }, 
    getPropiedades() {

      return new Promise((resolve, reject) => {                    
           this.buscando = true;   
          axios.get(CONFIG.urlApi+'propiedades/getPropiedades',{})
          .then(resp => {
              
              this.propiedades = resp.data.data;
              this.buscando = false;
          })
          .catch(err => {                  
              
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
              });
              
              this.buscando = false;

          });
                    
      });
    },
    getPropiedad(id) {

      return new Promise((resolve, reject) => {                    
           this.buscando = true;   
          axios.get(CONFIG.urlApi+'propiedades/getPropiedad/'+id,{})
          .then(resp => {
              
              this.propiedad = resp.data.data;
              this.buscando = false;
          })
          .catch(err => {                  
              
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
              });
              
              this.buscando = false;

          });
                    
      });
    },
    openModal(data,tipo) {

        this.tipo = tipo;
        this.propiedad = {};
        
        if(tipo == 'editar'){
          //this.propiedad = data;
          this.getPropiedad(data.id);
        }
        
        this.dialog = true;
    },
    agregar(){

      return new Promise((resolve, reject) => {
            this.buscando = true;
            this.propiedad.nuevasOpciones = this.nuevaOpcion;
            let data_propiedad = this.propiedad;
            axios({url: CONFIG.urlApi+'propiedades/agregar',data:data_propiedad,method: 'POST' })
            .then(resp => {
              
              this.propiedad = {};
              this.dialog = false;
              
              this.SnackbarShow({
                                    tipo: 'success',
                                    mensaje: resp.data.statusText
                                });

              this.getPropiedades();
              this.buscando = false;
              
          })
          .catch(err => {
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
                  });
              
              this.buscando = false;

          });
                    
      });

    },
    editar(){

      return new Promise((resolve, reject) => {
            this.buscando = true;
            this.propiedad.nuevasOpciones = this.nuevaOpcion;
            axios({url: CONFIG.urlApi+'propiedades/editar/'+this.propiedad.id,data:this.propiedad,method: 'PUT' })
            .then(resp => {

              this.propiedad = {};
              this.dialog = false;
              this.SnackbarShow({
                                    tipo: 'success',
                                    mensaje: resp.data.statusText
                                });

              this.getPropiedades();
              this.buscando = false;
              
          })
          .catch(err => {                  
              
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
                  });

              this.buscando = false;

          });
                    
      });

    },
    deleteItem (item_id) {
        this.propiedad.id = item_id;
        this.dialogDelete = true
    },
    agregarOpcionDinamica(){
      this.nuevaOpcion.push({
        nombre:'',
        accion:2
      });
    },
    eliminarOpcionDinamica(index){
      this.nuevaOpcion.splice(index, 1);
    },
    eliminarOpcion(id,indice){

      return new Promise((resolve, reject) => {
            this.buscando = true;            
            axios({url: CONFIG.urlApi+'opcionesPropiedades/eliminar/'+id,data:{},method: 'DELETE' })
            .then(resp => {

               this.propiedad.opciones.splice(indice, 1);
              
              /*this.SnackbarShow({
                                    tipo: 'success',
                                    mensaje: resp.data.statusText
                                });*/

              
              this.buscando = false;
              
          })
          .catch(err => {                  
              
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
                  });

              //this.buscando = false;

          });
                    
      });

    },
    deleteItemConfirm () {
        
      return new Promise((resolve, reject) => {                    
          
          this.buscando = true;          
          
          axios.delete(CONFIG.urlApi+'propiedades/eliminar/'+this.propiedad.id, {})
          .then(resp => {                
             
              this.propiedad.id = {};

              this.SnackbarShow({
                                    tipo: 'success',
                                    mensaje: resp.data.statusText
                                });

              this.getPropiedades();

              this.buscando = false;
              
          })
          .catch(err => {                  
              
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
                  });

              this.buscando = false;

          });
            this.closeDelete();
                    
      });
    },
    closeDelete () {
      this.dialogDelete = false
    },
  },
  watch: {
      dialogDelete (val) {
        val || this.closeDelete()
      },
  },
  created(){
    this.getPropiedades();
  }
  
}
</script>
